import { Callable } from '@prophecy/interfaces/generic';
import { Button, Dialog, Popover, SIDE_NAVBAR_WIDTH, Stack, theme, Tooltip } from '@prophecy/ui';
import { ReactComponent as Logo } from '@prophecy/ui/assets/images/logo.svg';
import {
  ActivityIcon,
  BookOpenAIcon,
  BuildingBIcon,
  DiamondCIcon,
  DotsHorizontalIcon,
  DownloadDIcon,
  HelpCircleIcon,
  Icon,
  LifeBuoyBIcon,
  LogOutDIcon,
  MetadataIcon,
  PlusIcon,
  SettingsBIcon
} from '@prophecy/ui/Icons';
import { hideScrollbarCss } from '@prophecy/ui/Misc/HideScrollbar';
import { parseJSON } from '@prophecy/utils/string';
import { useEffect, useState } from 'react';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components';

import { EntityIcon } from '../common/Entity/EntityIcons';
import { FabricSetupDialog } from '../common/Fabric/FabricSetup';
import { useUserFabrics } from '../common/Fabric/hooks';
import { useOnBoardingSkipFlow } from '../common/onboarding/hooks';
import { UserTeamsInfoAspectQuery } from '../common/queries/user';
import { Entity } from '../common/types/Entity';
import { Private_Routes, Public_Routes } from '../common/url';
import { useAppMetadata } from '../context/appMetadata';
import { useGlobalVariables } from '../context/globalConfig/securedConfigHook';
import { completeLogoutAndReloadPage } from '../data/apis/api';
import { useGraphQlQuery } from '../data/util';
import { AspectKind } from '../graphqlTypes/enums';
import {
  isDebugMode,
  LAST_JOB_IDE_URL,
  LAST_OBSERVATION_IDE_URL,
  LAST_VISITED_IDE_URL
} from '../utils/localstorage-keys';

type NavItemStyleProps = { $active?: boolean; disabled?: boolean };

const StyledLogo = styled(Logo)`
  width: ${theme.sizes.x32};
  height: ${theme.sizes.x32};
  cursor: pointer;
`;

const StyledSideNavbar = styled(Stack)`
  width: ${SIDE_NAVBAR_WIDTH};
  pointer-events: all;
  background-color: ${theme.colors.grey900};
  padding: ${theme.spaces.x12} 0;
  font-family: ${theme.fontFamily.text};
  flex-shrink: 0;
  overflow: auto;
  ${hideScrollbarCss};
`;

const navItemCSS = css<NavItemStyleProps>`
  color: ${theme.colors.white};
  border-radius: ${theme.radius.m};
  height: ${theme.sizes.x40};
  width: ${theme.sizes.x40};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.grey300};

  &:hover:not([disabled]) {
    color: ${theme.colors.white};
    background-color: ${theme.colors.blueish_grey700};
  }

  &[disabled] {
    color: ${theme.colors.grey500};
    pointer-events: none;
  }

  ${(props) =>
    props.$active &&
    `
    &&& {
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary600};
    }
  `}

  ${Icon} {
    color: inherit;
    font-size: ${theme.sizes.x24};
  }
`;

const StyledNavLink = styled(Button)<NavItemStyleProps>`
  ${navItemCSS}
`;

const StyledNavAnchor = styled.a<NavItemStyleProps>`
  ${navItemCSS}
`;

const StyledNavButton = styled.button`
  all: unset;
  ${navItemCSS}

  &[data-state="open"] {
    background-color: ${theme.colors.primary500};
    color: ${theme.colors.white};
  }
`;

const navItemCSSDark = css<NavItemStyleProps>`
  all: unset;

  ${navItemCSS}

  color: ${theme.colors.grey900};
  &&:hover {
    color: ${theme.colors.white};
    background: ${theme.colors.primary500};
  }
`;

const StyledNavLinkDark = styled(Button)<NavItemStyleProps>`
  ${navItemCSSDark}
  &[disabled] {
    color: ${theme.colors.grey700};
    pointer-events: none;
  }
` as typeof Button;

const StyledNavAnchorDark = styled.a<NavItemStyleProps>`
  ${navItemCSSDark}
`;

const Divider = styled.hr`
  background-color: ${theme.colors.grey600};
  height: 1px;
  border: none;
  border-radius: ${theme.radius.xl};
  width: ${theme.sizes.x40};
`;

const MoreNavPopoverStyles = createGlobalStyle`
  .side-nav-more-popover {
    width: ${SIDE_NAVBAR_WIDTH};
    min-width: ${SIDE_NAVBAR_WIDTH};
    padding: ${theme.spaces.x6} 0;
    
  }
`;

type NavItemProps = {
  to: string;
  redirectURL: string;
  message: string;
  external?: boolean;
  activeOnRoutes?: string[];
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  onDisabledNavClick?: (e: React.MouseEvent) => void;
};

function NavTooltip({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <Tooltip title={title} placement='right'>
      {children}
    </Tooltip>
  );
}

function NavItem({
  to,
  onClick,
  title,
  message,
  external,
  redirectURL,
  disabled,
  activeOnRoutes = [`${to}/*`],
  children,
  onDisabledNavClick
}: NavItemProps) {
  const location = useLocation();
  const globalConfigManager = useGlobalVariables();
  const { onSkip, navigate, sparkOnboardingUrl, sqlOnboardingUrl } = useOnBoardingSkipFlow();
  const pathName = location.pathname;
  const urlWithSearch = pathName + location.search;
  const isOnboardingIDE = urlWithSearch === sparkOnboardingUrl || urlWithSearch === sqlOnboardingUrl;
  const isOnboardingPage = pathName.endsWith('onboarding') || isOnboardingIDE;
  const handleDisabledNavClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const allowed = await Dialog.warning({
      title: '',
      subTitle: isOnboardingPage ? 'Do you want to skip onboarding?' : message,
      children: null,
      closeButton: isOnboardingPage ? { children: 'Cancel' } : null,
      okButton: { children: isOnboardingPage ? 'Skip' : 'OK', block: false }
    });
    if (allowed) {
      if (isOnboardingPage) {
        await onSkip();
        const redirectURL = globalConfigManager.getFabricStatus
          ? ''
          : Private_Routes.Create_Entity.tab.getUrl({ tab: Entity.Fabric.toLowerCase() });
        navigate(redirectURL);
      } else if (redirectURL && redirectURL !== pathName) {
        navigate(redirectURL);
      }
    }
  };

  const routeMatched = activeOnRoutes.some((pattern) => {
    const match = matchPath(pattern, location.pathname);
    if (match) {
      return true;
    }
    return false;
  });

  let nav;
  if (external) {
    nav = (
      <StyledNavAnchor onClick={onClick} href={to}>
        {children}
      </StyledNavAnchor>
    );
  } else if (disabled) {
    nav = (
      <StyledNavLink variant='plain' onClick={onDisabledNavClick || handleDisabledNavClick} $active={routeMatched}>
        {children}
      </StyledNavLink>
    );
  } else {
    nav = (
      <StyledNavLink as={Link} to={to} onClick={onClick} $active={routeMatched}>
        {children}
      </StyledNavLink>
    );
  }

  return <NavTooltip title={title}>{nav}</NavTooltip>;
}

function MoreNavPopover({ onClose }: { disabled: boolean; onClose: () => void }) {
  return (
    <Stack gap={theme.spaces.x8} alignY='center'>
      <MoreNavPopoverStyles />

      <NavTooltip title='Settings'>
        <StyledNavLinkDark
          onClick={onClose}
          variant='link'
          elementType='link'
          to={Private_Routes.Settings.home.getUrl()}>
          <SettingsBIcon type='default' />
        </StyledNavLinkDark>
      </NavTooltip>
      <NavTooltip title='Sign out'>
        <StyledNavAnchorDark onClick={() => completeLogoutAndReloadPage()} data-test-id='logout'>
          <LogOutDIcon type='default' />
        </StyledNavAnchorDark>
      </NavTooltip>
    </Stack>
  );
}
function SupportPopover({ onClose }: { onClose: () => void }) {
  return (
    <Stack gap={theme.spaces.x8} alignY='center'>
      <MoreNavPopoverStyles />

      <NavTooltip title='Docs'>
        <StyledNavAnchorDark onClick={onClose} target='_blank' href={Public_Routes.ProphecyDoc.getUrl()}>
          <BookOpenAIcon type='default' />
        </StyledNavAnchorDark>
      </NavTooltip>
      <NavTooltip title='Product Support'>
        <StyledNavAnchorDark onClick={onClose} target='_blank' href={Public_Routes.Help.getUrl()}>
          <LifeBuoyBIcon type='default' />
        </StyledNavAnchorDark>
      </NavTooltip>
    </Stack>
  );
}
type SideNavBarProps = {
  isPlanExpired: boolean;
  isOnboardingRequired: boolean;
};

export function SideNavBar({ isPlanExpired, isOnboardingRequired }: SideNavBarProps) {
  const globalConfigManager = useGlobalVariables();
  const isFabricSet = globalConfigManager.getFabricStatus;
  const [isMorePopover, toggleMorePopover] = useState(false);
  const [isSupportMorePopover, toggleSupportMorePopover] = useState(false);
  const isTranspilerNotUsable = !isFabricSet || isOnboardingRequired;
  const isTranspilerEnabledByGobalFlag = globalConfigManager.isImportEnabled || isDebugMode();
  const [isTranspilerEnabled, setIsTranspilerEnabled] = useState(
    !(isPlanExpired || isTranspilerNotUsable || !isTranspilerEnabledByGobalFlag)
  );
  const data = useAppMetadata();
  const navigate = useNavigate();
  const fabrics = useUserFabrics();
  const userId = data.user?.id;
  const variables = { uid: userId || '' };
  const { data: userTeamsAspectData, isSuccess: isUserTeamsAspectQuerySuccess } = useGraphQlQuery(
    [Entity.User, Entity.Team],
    UserTeamsInfoAspectQuery,
    { enabled: Boolean(!isTranspilerNotUsable && !isTranspilerEnabledByGobalFlag && userId) },
    variables
  );

  useEffect(() => {
    if (userTeamsAspectData?.User && isUserTeamsAspectQuerySuccess) {
      const isTranspilerEnabledForUser = userTeamsAspectData?.User.teams.some((team) =>
        team.InfoAspect.some((aspect) => {
          if (aspect.AspectName === AspectKind.Info) {
            const { transpilerEnabled } = parseJSON<{ transpilerEnabled: boolean }>(aspect.AspectValue, {
              transpilerEnabled: false
            });

            return transpilerEnabled;
          }

          return false;
        })
      );

      setIsTranspilerEnabled(isTranspilerEnabledForUser);
    }
  }, [isUserTeamsAspectQuerySuccess, userTeamsAspectData]);

  const openLastVisitedIDE = (e: React.MouseEvent, lsKey: string) => {
    // Note: we also need to handle onClick for as if pipeline url change, the sidebar doesn't rerender and the url doesn't update
    const ideUrl = localStorage.getItem(lsKey);
    if (ideUrl) {
      e.preventDefault();
      navigate(ideUrl);
    }
  };

  const [fabricMessage, setFabricMessage] = useState<string>();

  const navItemProps = (entityName: string) => {
    let message = '';
    if (isPlanExpired) {
      message = `You are trying to access ${entityName}. Your plan has been expired. Please contact support team.`;
    } else if (isOnboardingRequired) {
      message = `You are trying to access ${entityName}. Please complete onboarding steps first.`;
    }

    let onDisabledNavClick: Callable | undefined;
    if (!message && !isFabricSet) {
      onDisabledNavClick = () =>
        setFabricMessage(`You are trying to access ${entityName}. Please setup a Fabric first.`);
    }

    if (!message) {
      message = `${entityName} service is disabled. Please contact support team.`;
    }

    return {
      message,
      redirectURL: isOnboardingRequired ? Private_Routes.Onboarding.getUrl() : '',
      onDisabledNavClick
    };
  };

  return (
    <>
      <StyledSideNavbar align='space-between'>
        <Stack gap={theme.spaces.x8} alignY='center'>
          <>
            <Link to={Private_Routes.Home.getUrl()} data-test-id='prophecyLogo'>
              <StyledLogo />
            </Link>
            <Divider />
          </>
          <>
            <NavItem
              {...navItemProps('Metadata')}
              to={Private_Routes.User.home.getUrl()}
              disabled={isPlanExpired || !isFabricSet || isOnboardingRequired}
              title='Metadata'>
              <MetadataIcon size='m' type='extended' />
            </NavItem>
            <NavItem
              {...navItemProps('Entity page')}
              to={Private_Routes.Create_Entity.home.getUrl()}
              disabled={isPlanExpired || isOnboardingRequired}
              title='Create Entity'>
              <PlusIcon type='default' size='m' />
            </NavItem>
            <Divider />
            <NavItem
              {...navItemProps(`${Entity.Pipeline} IDE`)}
              onClick={(e) => openLastVisitedIDE(e, LAST_VISITED_IDE_URL)}
              to={Private_Routes.IDE_Home.getUrl({ entity: Entity.Pipeline.toLowerCase() })}
              activeOnRoutes={[
                Private_Routes.IDE_Home.url.replace(':entity', Entity.Pipeline.toLowerCase()),
                Private_Routes.IDE.url.replace(':entity', Entity.Pipeline.toLowerCase()),
                Private_Routes.IDE.url.replace(':entity', Entity.Configuration.toLowerCase()),
                Private_Routes.IDE.url.replace(':entity', Entity.Lineage.toLowerCase()),
                Private_Routes.SQL_IDE.url
              ]}
              disabled={isPlanExpired || !isFabricSet || isOnboardingRequired}
              title='IDE'>
              <EntityIcon hideTooltip={true} entity={Entity.Pipeline} size='m' />
            </NavItem>

            {globalConfigManager.isPlaygroundEnabled && (
              <NavItem
                {...navItemProps('Gem Builder')}
                to={Private_Routes.Gems.getUrl()}
                disabled={!isFabricSet || isOnboardingRequired}
                title='Gem Builder'>
                <DiamondCIcon type='extended' size='m' />
              </NavItem>
            )}
            <NavItem
              {...navItemProps(`${Entity.Job} IDE`)}
              onClick={(e) => openLastVisitedIDE(e, LAST_JOB_IDE_URL)}
              to={Private_Routes.IDE_Home.getUrl({ entity: Entity.Job.toLowerCase() })}
              activeOnRoutes={[
                Private_Routes.IDE_Home.url.replace(':entity', Entity.Job.toLowerCase()),
                Private_Routes.IDE.url.replace(':entity', Entity.Job.toLowerCase())
              ]}
              disabled={isPlanExpired || !isFabricSet || isOnboardingRequired}
              title='Job'>
              <EntityIcon hideTooltip={true} entity={Entity.Job} size='m' />
            </NavItem>
            <NavItem
              {...navItemProps('Observability')}
              onClick={(e) => openLastVisitedIDE(e, LAST_OBSERVATION_IDE_URL)}
              to={Private_Routes.IDE_Home.getUrl({ entity: Entity.Observation.toLowerCase() })}
              activeOnRoutes={[
                Private_Routes.IDE_Home.url.replace(':entity', Entity.Observation.toLowerCase()),
                Private_Routes.IDE.url.replace(':entity', Entity.Observation.toLowerCase())
              ]}
              disabled={
                !globalConfigManager.isDeploymentEnabled || isPlanExpired || !isFabricSet || isOnboardingRequired
              }
              title='Observability'>
              <ActivityIcon type='default' size='m' />
            </NavItem>
            <NavItem
              {...navItemProps('Lineage')}
              to={Private_Routes.Lineage_Search.getUrl(undefined)}
              disabled={!globalConfigManager.isLineageEnabled || isPlanExpired || !isFabricSet || isOnboardingRequired}
              title='Lineage'>
              <EntityIcon hideTooltip entity={Entity.Lineage} size='m' />
            </NavItem>
            <NavItem
              {...navItemProps('Transpiler')}
              to={Private_Routes.Transpiler.getUrl()}
              disabled={!isTranspilerEnabled}
              title='Transpiler'>
              <DownloadDIcon type='default' size='m' />
            </NavItem>
          </>
        </Stack>
        <Stack gap={theme.spaces.x8} alignY='center'>
          <NavItem
            {...navItemProps('Package Hub')}
            to={Private_Routes.PackageHubList.getUrl()}
            disabled={isPlanExpired || !isFabricSet || isOnboardingRequired}
            title='Package Hub'>
            <BuildingBIcon type='default' size='m' />
          </NavItem>
          <Divider />

          <Popover
            placement='right'
            overlayClassName='side-nav-more-popover'
            placementOffset={parseInt(theme.spaces.x24, 10)}
            align='end'
            visible={isSupportMorePopover}
            onVisibleChange={toggleSupportMorePopover}
            alignOffset={-10}
            overlay={<SupportPopover onClose={() => toggleSupportMorePopover(false)} />}>
            <StyledNavButton data-test-id='help-more-nav'>
              <HelpCircleIcon type='default' />
            </StyledNavButton>
          </Popover>
          <Popover
            placement='right'
            overlayClassName='side-nav-more-popover'
            placementOffset={parseInt(theme.spaces.x24, 10)}
            align='end'
            visible={isMorePopover}
            onVisibleChange={toggleMorePopover}
            alignOffset={-10}
            overlay={
              <MoreNavPopover
                onClose={() => toggleMorePopover(false)}
                disabled={!isFabricSet || isOnboardingRequired}
              />
            }>
            <StyledNavButton data-test-id='more-nav'>
              <DotsHorizontalIcon type='default' size='m' />
            </StyledNavButton>
          </Popover>
        </Stack>
      </StyledSideNavbar>
      {fabricMessage && (
        <FabricSetupDialog
          message={fabricMessage}
          onClose={() => setFabricMessage('')}
          navigate={navigate}
          fabrics={fabrics}
          globalConf={globalConfigManager}
        />
      )}
    </>
  );
}
