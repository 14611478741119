import { useEffect } from 'react';
import { usePersistentCallback, useStateRef } from './react/hooks';
export var KeyCodes;
(function (KeyCodes) {
    KeyCodes["Delete"] = "Delete";
    KeyCodes["Backspace"] = "Backspace";
    KeyCodes["Alt"] = "Alt";
    KeyCodes["Shift"] = "Shift";
    KeyCodes["Ctrl"] = "Control";
    KeyCodes["Meta"] = "Meta";
    KeyCodes["Escape"] = "Escape";
    KeyCodes["Enter"] = "Enter";
    KeyCodes["A"] = "a";
    KeyCodes["Z"] = "z";
    KeyCodes["Y"] = "y";
    KeyCodes["C"] = "c";
    KeyCodes["X"] = "x";
    KeyCodes["V"] = "v";
    KeyCodes["F"] = "f";
    KeyCodes["ArrowDown"] = "ArrowDown";
    KeyCodes["ArrowUp"] = "ArrowUp";
    /** None means non-existing keycode */
    KeyCodes["_None"] = "none";
})(KeyCodes || (KeyCodes = {}));
// TODO: check out if this library can be used instead https://github.com/wbkd/react-flow/blob/main/packages/core/src/hooks/useKeyPress.ts
export const initialActiveKeys = {
    [KeyCodes.Ctrl]: false,
    [KeyCodes.Shift]: false,
    [KeyCodes.Alt]: false,
    [KeyCodes.Meta]: false
};
export function useActiveControlKeys() {
    const [activeCtrlKeys, setActiveCtrlKeys] = useStateRef(initialActiveKeys);
    useEffect(() => {
        const keydownHandler = (event) => {
            if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) {
                setActiveCtrlKeys({
                    [KeyCodes.Ctrl]: event.ctrlKey,
                    [KeyCodes.Shift]: event.shiftKey,
                    [KeyCodes.Alt]: event.altKey,
                    [KeyCodes.Meta]: event.metaKey
                });
            }
        };
        const keyupHandler = (event) => {
            if (activeCtrlKeys.current !== initialActiveKeys) {
                setActiveCtrlKeys(initialActiveKeys);
            }
        };
        window.addEventListener('keydown', keydownHandler);
        window.addEventListener('keyup', keyupHandler);
        return () => {
            window.removeEventListener('keydown', keydownHandler);
            window.removeEventListener('keyup', keyupHandler);
        };
    }, [activeCtrlKeys, setActiveCtrlKeys]);
    return activeCtrlKeys.current;
}
export const useKeyPress = (keyCode, callback) => {
    const handleKeyPress = usePersistentCallback((event) => {
        event.stopPropagation();
        if (event.key === keyCode) {
            callback(event);
        }
    });
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);
};
