import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { produceWithoutFreeze } from '@prophecy/utils/data';
import { getIndexAndParentPath, PATH_KEY, updatePath } from '@prophecy/utils/nestedData';
import { useDebounce, useFrameThrottle, useThrottle } from '@prophecy/utils/react/hooks';
import { caseInsensitiveInclude } from '@prophecy/utils/string';
import { throttle } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { Ellipsis, overlayStyle } from '../Ellipsis';
import { PlusIcon } from '../Icons';
import { loadMoreKey } from './ColumnSort';
import { StyledReorder, StyledMenuOutlined, RowActionButtonWrapper, StyledDropdownOverlay, ghostDragElementStyles, TableActions, CellRenderer } from './styled';
import { SCROLL_POSITION_THRESHOLD } from './tokens';
import { RowActionType, RowPosition, ScrollPosition } from './types';
import { haveSameParent, isCreatableRow, isRowExpanded } from './utils';
const getColumnWithEllipsis = (title, className) => {
    return (_jsx(Ellipsis, { tooltip: true, className: className, tooltipProps: {
            overlayStyle
        }, children: title }));
};
export function useEllipsisInTable(columns) {
    return columns.map((column) => {
        if ('children' in column || column.hideEllipsis)
            return column;
        let title = column === null || column === void 0 ? void 0 : column.title;
        title = title && getColumnWithEllipsis(title, column === null || column === void 0 ? void 0 : column.className);
        return Object.assign(Object.assign({}, column), { title });
    });
}
export function useComputedValue(columns) {
    return columns.map((column) => {
        if (!column.computedValue)
            return column;
        const oldRender = column.render;
        return Object.assign(Object.assign({}, column), { render: (_, record, index) => {
                var _a;
                return oldRender === null || oldRender === void 0 ? void 0 : oldRender((_a = column.computedValue) === null || _a === void 0 ? void 0 : _a.call(column, record), record, index);
            } });
    });
}
export function usePlaceholderRows(placeholderRows, columns, dataSource, rowKey) {
    if (!placeholderRows)
        return { columns, dataSource, getPlaceholderRowProps: () => ({}) };
    const getRender = (column) => (col, row, index) => {
        if (row.__isDummyRow)
            return _jsx("div", { className: 'dummy-cell' });
        else if (column.render)
            return column.render(col, row, index);
        return col;
    };
    // hack to add additional rows which follows common layout without those rows being parsed by Column configuration
    const _columns = columns.map((column, i) => {
        /**
         * if its a column has children property its column group or else column,
         * we don't have to do anything for column group just mutate the column
         */
        if ('children' in column) {
            return Object.assign(Object.assign({}, column), { children: column.children.map((child) => {
                    return Object.assign(Object.assign({}, child), { render: getRender(child) });
                }) });
        }
        return Object.assign(Object.assign({}, column), { render: getRender(column) });
    });
    // add dummy rows at the end
    const dummyRows = Array.from({ length: placeholderRows }).map((row, idx) => ({
        __isDummyRow: true,
        [rowKey]: `dummy-row-${idx}`
    }));
    const _dataSource = placeholderRows ? [...dataSource, ...dummyRows] : dataSource;
    return {
        columns: _columns,
        dataSource: _dataSource,
        getPlaceholderRowProps: (row) => {
            if (row.__isDummyRow)
                return { className: 'dummy-row' };
            return {};
        }
    };
}
function getDefaultReorderRow(...args) {
    const [row] = args;
    return {
        row
    };
}
export function useTableReorder({ reorder, columns, dataSource, selectionState }) {
    const rowToMoveInfo = useRef();
    const dropElm = useRef(null);
    const [reorderInProgress, setReorderInProgress] = useState(false);
    const { onReorder, allowMultiLevelReorder, isReorderDropAllowed = () => true, getReorderRows = getDefaultReorderRow } = reorder || {};
    const removeClass = (elm) => elm === null || elm === void 0 ? void 0 : elm.classList.remove('drop-hint', 'drop-before', 'drop-after');
    const addClass = (e, row) => {
        if (!rowToMoveInfo.current)
            return;
        const rowElm = e.currentTarget;
        if (!rowElm)
            return;
        const rowToMove = rowToMoveInfo.current.row;
        const rowDataPath = row[PATH_KEY];
        const rowToMovePath = rowToMove[PATH_KEY];
        const inSameParent = haveSameParent(rowToMovePath, rowDataPath);
        // don't show drop hint on different level if allowMultiLevelReorder is not set, or trying to drop on same position
        if ((!allowMultiLevelReorder && !inSameParent) || rowDataPath === rowToMovePath) {
            return;
        }
        // don't show drop hint if trying to drop parent on its own children
        if (rowDataPath.startsWith(rowToMovePath))
            return;
        const rowBoundingRect = rowElm.getBoundingClientRect();
        const dropPosition = e.pageY > rowBoundingRect.top + rowBoundingRect.height / 2 ? RowPosition.after : RowPosition.before;
        removeClass(rowElm);
        if (dropPosition === RowPosition.after && isRowExpanded(rowElm, row)) {
            // if row is expanded and drop position is after, we drop before the first children
            row = row.children[0];
        }
        if (isReorderDropAllowed(rowToMove, row, dropPosition)) {
            rowElm.classList.add('drop-hint', `drop-${dropPosition}`);
        }
    };
    const throttledAddClass = useThrottle(addClass, 300);
    const onDragStart = (e, row, rowIndex) => {
        var _a;
        e.stopPropagation();
        setReorderInProgress(true);
        const target = e.target;
        const rowElm = target.closest('tr');
        // get selected rows information
        const rowsToReorder = getReorderRows(row, selectionState);
        row = rowsToReorder.row;
        const path = row[PATH_KEY];
        const { index } = getIndexAndParentPath(row[PATH_KEY]);
        let { dragMessage } = rowsToReorder;
        const { selectedRows = [] } = rowsToReorder;
        rowToMoveInfo.current = {
            row,
            index
        };
        /**
         * if multiple items are selected, and the current row to be dragged is part of the selected rows
         * then show multiple drag message and include all selection for drag
         */
        if ((selectedRows.length > 1 && selectedRows.some((row) => row.path === path)) || dragMessage) {
            rowToMoveInfo.current.selectedRows = selectedRows;
            dragMessage = dragMessage || `Moving ${selectedRows.length} items.`;
        }
        // if drag message is present show that as ghost image
        if (dragMessage) {
            // create a ghost element, append it temporarily to get the drag image
            const moveImagElm = document.createElement('div');
            moveImagElm.style.cssText = ghostDragElementStyles;
            moveImagElm.innerText = dragMessage ? dragMessage : `Moving ${selectedRows.length} items.`;
            (_a = rowElm.closest('.ui-table')) === null || _a === void 0 ? void 0 : _a.appendChild(moveImagElm);
            e.dataTransfer.setDragImage(moveImagElm, 5, 20);
            setTimeout(() => {
                moveImagElm.remove();
            });
        }
        else {
            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.setDragImage(rowElm, 5, 20);
        }
    };
    const onDragEnd = (e) => {
        e.stopPropagation();
        rowToMoveInfo.current = undefined;
        removeClass(dropElm.current);
        setReorderInProgress(false);
    };
    const onDragEnter = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        // if drag is over dummy row no need to do anything
        if (row.__isDummyRow)
            return;
        const rowElm = e.currentTarget;
        dropElm.current = rowElm;
        addClass(e, row);
    };
    const onDragOver = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        // if drag is over dummy row no need to do anything
        if (row.__isDummyRow)
            return;
        throttledAddClass(e, row);
    };
    const onDragLeave = (e) => {
        e.stopPropagation();
        if (!rowToMoveInfo.current)
            return;
        const target = e.currentTarget;
        // if leave is happening outside
        if (target !== dropElm.current) {
            removeClass(target);
        }
    };
    const onDrop = (e, row) => {
        e.stopPropagation();
        if (!rowToMoveInfo.current)
            return;
        const rowElm = e.currentTarget;
        // if drop position is not present don't drop
        if (!rowElm.classList.contains('drop-hint'))
            return;
        let dropAfter = rowElm.classList.contains('drop-after');
        removeClass(rowElm);
        // if we are dropping after a row which is expended, add the new item before its first children
        if (dropAfter && isRowExpanded(rowElm, row)) {
            row = row.children[0];
            dropAfter = false;
        }
        const { index: rowIndex } = getIndexAndParentPath(row[PATH_KEY]);
        let dropIndex = rowIndex;
        // add it after the row if dropAfter is set
        if (dropAfter) {
            dropIndex = dropIndex + 1;
        }
        const inSameParent = haveSameParent(rowToMoveInfo.current.row[PATH_KEY], row[PATH_KEY]);
        // reorder the element only when its on same parent if allowMultiLevelReorder is not set
        if (rowToMoveInfo.current.row !== row && (inSameParent || allowMultiLevelReorder)) {
            const { selectedRows, index: fromIndex } = rowToMoveInfo.current;
            onReorder === null || onReorder === void 0 ? void 0 : onReorder({
                row: rowToMoveInfo.current.row,
                toIndex: dropIndex,
                fromIndex,
                selectedRows: selectedRows,
                multiple: selectedRows && selectedRows.length > 1,
                currentRow: row
            });
        }
    };
    if (!(reorder === null || reorder === void 0 ? void 0 : reorder.onReorder))
        return { columns, getReorderProps: () => ({}) };
    const columnsWithReorder = [
        {
            title: '',
            width: '24px',
            key: 'reorder-cell',
            className: 'reorder-cell',
            resizable: false,
            render(col, row, rowIndex) {
                var _a;
                // don't show reorder icon for creatable row
                if (isCreatableRow(row, rowIndex, dataSource) || (row === null || row === void 0 ? void 0 : row.disabled) || ((_a = row === null || row === void 0 ? void 0 : row.params) === null || _a === void 0 ? void 0 : _a.reorderDisabled))
                    return null;
                return (_jsx(TableActions, { children: _jsx(StyledReorder, { align: 'center', draggable: true, onDragStart: (e) => onDragStart(e, row, rowIndex), onDragEnd: onDragEnd, children: _jsx(StyledMenuOutlined, { type: 'extended' }) }) }));
            }
        },
        ...columns
    ];
    const getReorderProps = (row) => {
        return {
            onDragOver: (e) => onDragOver(e, row),
            onDragEnter: (e) => onDragEnter(e, row),
            onDragLeave,
            onDrop: (e) => onDrop(e, row)
        };
    };
    return {
        columns: columnsWithReorder,
        getReorderProps,
        reorderInProgress
    };
}
export function useDataWithRecordPath(dataSource) {
    const hasPath = useRef(undefined);
    /**
     * if path information is coming from parent, don't bother to add it again.
     * We need to apply this check only the first time data is coming. There can be
     * a case where data is empty fist time
     */
    if (hasPath.current === undefined && dataSource.length && dataSource[0][PATH_KEY]) {
        hasPath.current = true;
    }
    return useMemo(() => {
        // if path information is added from parent
        if (hasPath.current)
            return dataSource;
        return produceWithoutFreeze(dataSource, (draft) => {
            updatePath(draft, '');
        });
    }, [dataSource]);
}
export function useRowActions(containerRef, rowAction) {
    var _a;
    const [isScrolling, setScrolling] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [hoveredRowInfo, setHoveredRowInfo] = useState();
    const scrollRef = useRef(null);
    const tableBody = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ui-table-body,.ui-table-tbody');
    const hasRowActions = !!rowAction;
    const onRowHover = useThrottle((rowElm, pageY, row) => {
        if (isScrolling || !hasRowActions || row.__isDummyRow) {
            // remove any previous set hovered row info
            if (hoveredRowInfo)
                setHoveredRowInfo(undefined);
            return;
        }
        const rowBoundingRect = rowElm.getBoundingClientRect();
        const buttonPosition = pageY > rowBoundingRect.top + rowBoundingRect.height / 2 ? RowPosition.after : RowPosition.before;
        if (buttonPosition === RowPosition.after && isRowExpanded(rowElm, row)) {
            // if row is expanded and drop buttonPosition is after, we do actions before the first children
            row = row.children[0];
        }
        if (!hoveredRowInfo || hoveredRowInfo.row !== row || hoveredRowInfo.buttonPosition !== buttonPosition) {
            setHoveredRowInfo({ row, buttonPosition, boundingRect: rowBoundingRect });
        }
    }, 100, { leading: true, trailing: true });
    useEffect(() => {
        if (!tableBody || !hasRowActions)
            return;
        const onScroll = throttle(() => {
            setScrolling(true);
            if (scrollRef.current)
                clearTimeout(scrollRef.current);
            scrollRef.current = setTimeout(() => {
                setScrolling(false);
            }, 1000);
        }, 16, // throttle per frame
        { trailing: true });
        // remove the hovered action button on hover out of table as hovered button is added on top
        const onMouseLeave = () => {
            // cancel any pending throttle
            onRowHover.cancel();
            setHoveredRowInfo(undefined);
            setDropdownVisible(false);
        };
        tableBody.addEventListener('scroll', onScroll);
        const el = containerRef.current;
        el === null || el === void 0 ? void 0 : el.addEventListener('mouseleave', onMouseLeave);
        return () => {
            tableBody.removeEventListener('scroll', onScroll);
            el === null || el === void 0 ? void 0 : el.removeEventListener('mouseover', onMouseLeave);
            if (scrollRef.current)
                clearTimeout(scrollRef.current);
        };
    }, [tableBody, hasRowActions, onRowHover, containerRef]);
    const getRowActionProps = (row) => {
        return {
            onMouseMove: (e) => {
                onRowHover(e.currentTarget, e.pageY, row);
            }
        };
    };
    const onDropdownToggle = (visible) => {
        if (!visible) {
            setHoveredRowInfo(undefined);
        }
        setDropdownVisible(visible);
    };
    let rowActionButton = null;
    if (hoveredRowInfo && rowAction) {
        const { type, renderButton = () => _jsx(Button, { icon: _jsx(PlusIcon, { type: 'default' }), size: 'xs' }), renderDropdown } = rowAction;
        const { row, buttonPosition, boundingRect } = hoveredRowInfo;
        const rowActionDropdown = renderDropdown === null || renderDropdown === void 0 ? void 0 : renderDropdown(row, buttonPosition);
        const button = renderButton(row, buttonPosition);
        /**
         * For dropdown type row action, dropdown menu also need to be available to show the action button
         * in other case we only need to show the button if it is available
         */
        const showActionButton = button && ((type === RowActionType.dropdown && rowActionDropdown) || type === RowActionType.button);
        const top = buttonPosition === RowPosition.before ? boundingRect.top : boundingRect.top + boundingRect.height;
        const left = boundingRect.left - 10;
        rowActionButton = showActionButton ? (
        /** Inline style is required as the styles are calculated */
        _jsx(RowActionButtonWrapper, { style: { top, left }, children: type === RowActionType.dropdown ? (_jsxs(_Fragment, { children: [dropdownVisible && _jsx(StyledDropdownOverlay, {}), _jsx(Dropdown, { overlay: rowActionDropdown, portalled: false, visible: dropdownVisible, overlayStyle: { minWidth: '100px' }, onVisibleChange: onDropdownToggle, children: button })] })) : (button) })) : null;
    }
    return {
        getRowActionProps,
        rowActionButton
    };
}
export function useTableScrollState(scrollBody, data) {
    const [scrollPosition, setScrollPosition] = useState(ScrollPosition.top);
    const [hasScroll, setHasScrolled] = useState(false);
    const onScroll = useFrameThrottle((scrollBody) => {
        const { scrollTop } = scrollBody;
        if (scrollTop <= SCROLL_POSITION_THRESHOLD) {
            setScrollPosition(ScrollPosition.top);
        }
        else if (scrollTop + scrollBody.clientHeight >= scrollBody.scrollHeight - SCROLL_POSITION_THRESHOLD) {
            setScrollPosition(ScrollPosition.bottom);
        }
        else {
            setScrollPosition(ScrollPosition.between);
        }
    });
    useEffect(() => {
        if (!scrollBody)
            return;
        const _onScroll = () => {
            onScroll(scrollBody);
        };
        scrollBody.addEventListener('scroll', _onScroll);
        // listen on scroll height change to update hasScroll state
        const observer = new ResizeObserver(() => {
            setHasScrolled(scrollBody.scrollHeight > scrollBody.clientHeight);
        });
        observer.observe(scrollBody);
        return () => {
            scrollBody.removeEventListener('scroll', _onScroll);
            observer.disconnect();
        };
    }, [scrollBody, onScroll]);
    useEffect(() => {
        if (!scrollBody || !data.length)
            return;
        setHasScrolled(scrollBody.scrollHeight > scrollBody.clientHeight);
    }, [scrollBody, data.length]);
    return {
        scrollPosition,
        hasScroll
    };
}
export const useTableSearch = (tableFilteredColumns, tableData) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [focusedCellMatchIndex, setFocusedCellMatchIndex] = useState(-1);
    const [searchMatchedCells, setSearchMatchedCells] = useState([]);
    const columnNameMap = useMemo(() => Object.fromEntries(tableFilteredColumns.map((column, index) => {
        return [column.name, index];
    })), [tableFilteredColumns]);
    const debouncedSearch = useDebounce(() => {
        const matchedCells = [];
        if (searchQuery.length > 0) {
            tableData.forEach((row, rowIndex) => {
                Object.keys(row).forEach((columnName) => {
                    if (row[columnName] &&
                        caseInsensitiveInclude(row[columnName].toString(), searchQuery) &&
                        row.rowKey !== loadMoreKey) {
                        matchedCells.push({
                            rowIndex,
                            colIndex: columnNameMap[columnName] === undefined ? -1 : columnNameMap[columnName]
                        });
                    }
                });
            });
        }
        setSearchMatchedCells(matchedCells);
        setFocusedCellMatchIndex(matchedCells.length > 0 ? 1 : -1);
    }, 300);
    useEffect(debouncedSearch, [columnNameMap, debouncedSearch, searchQuery, tableData]);
    const goNext = () => setFocusedCellMatchIndex((state) => state + 1);
    const goPrevious = () => setFocusedCellMatchIndex((state) => state - 1);
    return {
        focusedCellMatchIndex,
        searchMatchedCells,
        goNext,
        goPrevious,
        searchQuery,
        setSearchQuery
    };
};
export function useSearchStyleInCols(columns, defaultRowHeight, searchProps) {
    const { searchMatchedCells, focusedCellMatchIndex } = searchProps || {};
    const searchCellIdMap = useMemo(() => (!searchMatchedCells ? new Set() : new Set(searchMatchedCells.map((c) => `${c.rowIndex}~${c.colIndex}`))), [searchMatchedCells]);
    const focusedCell = focusedCellMatchIndex ? searchMatchedCells === null || searchMatchedCells === void 0 ? void 0 : searchMatchedCells[focusedCellMatchIndex - 1] : undefined;
    const focusedCellId = focusedCell && `${focusedCell.rowIndex}~${focusedCell.colIndex}`;
    if (!searchProps)
        return columns;
    return columns.map((column, colId) => {
        const currentRender = column.render;
        column.render = (value, _record, rowId) => {
            const cellId = `${rowId}~${colId}`;
            return (_jsx(CellRenderer, { "$height": defaultRowHeight - 1, "$isFocused": focusedCellId === cellId, "$isHighlighted": searchCellIdMap.has(cellId), children: currentRender ? currentRender(value, _record, rowId) : value }));
        };
        return column;
    });
}
