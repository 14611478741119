import { createLSPMethodHook } from '../hook';
import { useBaseLSPClient } from './BaseLSPContext';
import {
  callAction,
  changeProperties,
  changeTextDocument,
  metadataDidReload,
  resetProperty,
  saveProperty,
  saveTextDocument,
  loadView,
  generateExecutableCode,
  metadataGetDatasets,
  generateExpressionSuggestions,
  generateGraphSuggestions,
  getSuggestionGraphDifference,
  createTextDocument,
  deleteTextDocument,
  moveTextDocument,
  switchTextDocument,
  createGem,
  generateModificationSuggestions,
  copilotDescribe,
  getLabelSuggestion,
  getScriptSuggestion,
  publishTextDocument,
  compileProperty,
  getUdfSuggestion,
  dataExplorerSaveAsGem,
  getMacroSuggestion,
  getDataTestSuggestion,
  copilotFix,
  suggestGemProperties,
  cancelCompilation,
  metadataCreateJob,
  getCopilotGraphById,
  suggestPrompts,
  copilotSpecFlow,
  promoteProperties,
  copilotEditBusinessSpec,
  getFullBusinessRule,
  getTimeBasedBusinessRule
} from './methods';
import { LSP } from './types';

const BaseLSPMethods = {
  [LSP.Method.propertiesDidChange]: changeProperties,
  [LSP.Method.propertiesDidPromote]: promoteProperties,
  [LSP.Method.propertiesDidSave]: saveProperty,
  [LSP.Method.propertiesDidCancel]: cancelCompilation,
  [LSP.Method.propertiesDidReset]: resetProperty,
  [LSP.Method.propertiesDidAction]: callAction,
  [LSP.Method.textDocumentDidChange]: changeTextDocument,
  [LSP.Method.textDocumentDidSave]: saveTextDocument,
  [LSP.Method.metadataDidReload]: metadataDidReload,
  [LSP.Method.editorLoadView]: loadView,
  [LSP.Method.generateCode]: generateExecutableCode,
  [LSP.Method.metadataGetDatasets]: metadataGetDatasets,
  [LSP.Method.generateExpressionSuggestions]: generateExpressionSuggestions,
  [LSP.Method.generateGraphSuggestions]: generateGraphSuggestions,
  [LSP.Method.textDocumentDidCreate]: createTextDocument,
  [LSP.Method.textDocumentDidDelete]: deleteTextDocument,
  [LSP.Method.textDocumentDidMove]: moveTextDocument,
  [LSP.Method.textDocumentDidSwitch]: switchTextDocument,
  [LSP.Method.textDocumentDidPublish]: publishTextDocument,
  [LSP.Method.createGem]: createGem,
  [LSP.Method.getSuggestionGraphDifference]: getSuggestionGraphDifference,
  [LSP.Method.generateModificationSuggestions]: generateModificationSuggestions,
  [LSP.Method.copilotDescribe]: copilotDescribe,
  [LSP.Method.getLabelSuggestion]: getLabelSuggestion,
  [LSP.Method.getScriptSuggestion]: getScriptSuggestion,
  [LSP.Method.propertiesDidCompile]: compileProperty,
  [LSP.Method.getUdf]: getUdfSuggestion,
  [LSP.Method.getMacro]: getMacroSuggestion,
  [LSP.Method.getGenericDataTest]: getDataTestSuggestion,
  [LSP.Method.copilotFix]: copilotFix,
  [LSP.Method.suggestGemProperties]: suggestGemProperties,
  [LSP.Method.suggestPrompts]: suggestPrompts,
  [LSP.Method.dataExplorerSaveAsGem]: dataExplorerSaveAsGem,
  [LSP.Method.metadataCreateJob]: metadataCreateJob,
  [LSP.Method.getCopilotGraphById]: getCopilotGraphById,
  [LSP.Method.copilotSpecFlow]: copilotSpecFlow,
  [LSP.Method.copilotEditBusinessSpec]: copilotEditBusinessSpec,
  [LSP.Method.getBusinessRule]: getTimeBasedBusinessRule,
  [LSP.Method.getBusinessRuleFull]: getFullBusinessRule
};

const BaseLSPMethodDefaults = {
  [LSP.Method.propertiesDidChange]: { skipStateUpdate: true },
  [LSP.Method.propertiesDidAction]: { showGlobalLoader: true },
  [LSP.Method.metadataDidReload]: { showGlobalLoader: true },
  [LSP.Method.textDocumentDidCreate]: { showGlobalLoader: true },
  [LSP.Method.textDocumentDidDelete]: { showGlobalLoader: true },
  [LSP.Method.textDocumentDidMove]: { showGlobalLoader: true },
  [LSP.Method.createGem]: { showGlobalLoader: true }
};

export const useLSPMethod = createLSPMethodHook(BaseLSPMethods, BaseLSPMethodDefaults, useBaseLSPClient);

export type PropertiesDidChange = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.propertiesDidChange]>;
export type PropertiesDidSave = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.propertiesDidSave]>;
export type PropertiesDidAction = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.propertiesDidAction]>;

export type MetadataDidReload = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.metadataDidReload]>;
export type TextDocumentDidCreate = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.textDocumentDidCreate]>;
export type TextDocumentDidDelete = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.textDocumentDidDelete]>;
export type TextDocumentDidMove = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.textDocumentDidMove]>;
export type TextDocumentDidSwitch = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.textDocumentDidSwitch]>;
export type CreateGem = LSP.MethodType<(typeof BaseLSPMethods)[LSP.Method.createGem]>;
