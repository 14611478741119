import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Picker } from 'rc-picker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useControlledState } from '../../../../utils/src/react/hooks';
import { ChevronLeftIcon, ChevronRightIcon } from '../../Icons';
import 'rc-picker/assets/index.css';
import { CellRenderer } from '../components/CellRenderer';
import { PanelRenderer } from '../components/DatePanelRenderer';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../constant';
import { StyledPickerContainer, PickerDropdownBorderEraser } from '../styled';
import { PREFIX_CLASS } from '../tokens';
import { getLocale } from '../utils/utils';
export const DefaultDatePicker = function (_a) {
    var { events, showActionFooter, onApply, onCancel, showTime, dateFormat = DEFAULT_DATE_FORMAT, timeFormat = DEFAULT_TIME_FORMAT } = _a, props = __rest(_a, ["events", "showActionFooter", "onApply", "onCancel", "showTime", "dateFormat", "timeFormat"]);
    const [val, setVal] = useControlledState({
        value: props.value,
        onChange: props.onChange,
        defaultValue: undefined
    });
    const containerElement = useRef(null);
    const [mode, setMode] = useState('date');
    return (_jsxs(StyledPickerContainer, { renderAsPanel: true, ref: containerElement, children: [_jsx(PickerDropdownBorderEraser, {}), _jsx(Picker, { prefixCls: PREFIX_CLASS, value: val, onPickerValueChange: (date, info) => {
                    // when val is set to null, onPickerValueChange is called with a valid date and
                    //  info.source as reset
                    if (info.source !== 'reset') {
                        setVal(date);
                    }
                }, onChange: (date) => setVal(date), generateConfig: dateFnsGenerateConfig, locale: getLocale(), mode: mode, open: true, prevIcon: _jsx(ChevronLeftIcon, { type: 'solid', height: 20, width: 20 }), nextIcon: _jsx(ChevronRightIcon, { type: 'solid', height: 20, width: 20 }), superPrevIcon: _jsx(ChevronLeftIcon, { type: 'solid', height: 20, width: 20 }), superNextIcon: _jsx(ChevronRightIcon, { type: 'solid', height: 20, width: 20 }), cellRender: (date, info) => (_jsx(CellRenderer, { date: date, info: info, selectedDate: val, events: events, isRangePicker: false })), panelRender: (node) => {
                    const panel = (_jsx(PanelRenderer, { originalPanelRenderer: node, isRangePicker: false, showActionFooter: showActionFooter, onCancel: () => onCancel === null || onCancel === void 0 ? void 0 : onCancel(val), onApply: () => onApply === null || onApply === void 0 ? void 0 : onApply(val), showTimePicker: showTime, mode: mode }));
                    if (containerElement.current) {
                        return createPortal(panel, containerElement.current);
                    }
                    return panel;
                }, showTime: showTime, format: showTime ? dateFormat + ', ' + timeFormat : dateFormat, onPanelChange: (_, newMode) => {
                    setMode(newMode);
                } })] }));
};
