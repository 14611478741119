import { Callable } from '@prophecy/interfaces/generic';

export namespace LSP {
  export enum Notification {
    open = 'open',
    updateExecutableCode = 'updateExecutableCode',
    close = 'close',
    error = 'error',
    propertiesPublishDiagnostics = 'properties/publishDiagnostics',
    logMessage = 'window/logMessage',
    componentsDidOpen = 'components/didOpen',
    componentsDidUpdate = 'components/didUpdate',
    propertiesDidOpen = 'properties/didOpen',
    metadataPropertiesDidOpen = 'metadataProperties/didOpen',
    propertiesDidUpdate = 'properties/didUpdate',
    metadataDidOpen = 'metadata/didOpen',
    metadataDidUpdate = 'metadata/didUpdate',
    metadataReloadNeeded = 'metadata/reloadNeeded',
    compileProgress = 'compile/progress',
    textDocumentsDidUpdate = 'textDocuments/didUpdate',
    textDocumentsDidOpen = 'textDocuments/didOpen',
    // textDocumentPublishDiagnostics = 'textDocument/publishDiagnostics',
    textDocumentsPublishDiagnostics = 'textDocuments/publishDiagnostics',
    textDocumentsMetadataDidChange = 'textDocumentsMetadata/didUpdate',
    dependencyUpdate = 'dependency/update',
    ping = 'ping',
    // Dependencies
    dependenciesDidResolve = 'dependencies/resolved',
    windowShowMessage = 'window/showMessage',
    copilotTranscribe = 'copilot/transcribe'
  }
  export enum Method {
    initialize = 'initialize',

    textDocumentDidCreate = 'textDocument/didCreate',
    textDocumentDidDelete = 'textDocument/didDelete',
    textDocumentDidMove = 'textDocument/didMove',
    textDocumentDidSwitch = 'textDocument/didSwitch',
    createGem = 'editor/createGem',

    textDocumentDidChange = 'textDocument/didChange',
    textDocumentDidSave = 'textDocument/didSave',
    textDocumentDidCompile = 'textDocument/didCompile',
    textDocumentDidPublish = 'textDocument/didPublish',
    propertiesDidChange = 'properties/didChange',
    propertiesDidPromote = 'properties/didPromote',
    propertiesDidSave = 'properties/didSave',
    propertiesDidReset = 'properties/didReset',
    propertiesDidCancel = 'properties/didCancel',
    propertiesDidAction = 'properties/didAction',
    propertiesDidCompile = 'properties/didCompile',
    editorLoadView = 'editor/loadView',
    generateCode = 'codegen/generateCode',
    metadataDidReload = 'metadata/didReload',
    metadataGetDatasets = 'metadata/getDatasets',
    generateExpressionSuggestions = 'copilot/getExpression',
    generateGraphSuggestions = 'copilot/getGraph',
    getSuggestionGraphDifference = 'copilot/getGraphDifference',
    generateModificationSuggestions = 'copilot/modifyGraph',
    copilotDescribe = 'copilot/describe',
    getLabelSuggestion = 'copilot/getLabel',
    getScriptSuggestion = 'copilot/getScript',
    getUdf = 'copilot/getUdf',
    getMacro = 'copilot/getMacro',
    getGenericDataTest = 'copilot/getGenericDataTest',
    copilotFix = 'copilot/fix',
    copilotEditBusinessSpec = 'copilot/editBusinessSpec',
    getCopilotGraphById = 'copilot/context',
    suggestGemProperties = 'copilot/suggestGemProperties',
    suggestPrompts = 'copilot/suggestPrompts',
    getBusinessRule = 'copilot/getBusinessRule',
    getBusinessRuleFull = 'copilot/getBusinessRuleFull',
    dataExplorerSaveAsGem = 'dataexplorer/saveAsGem',
    copilotSpecFlow = 'copilot/specflow',
    pong = 'pong',

    metadataCreateJob = 'metadata/createJob'
  }

  export type Error = {
    message: string;
    data?: string;
    code?: number;
  };

  export type Success = {
    message: string;
    type: string;
  };
  export type CallbackReturnType = {
    data?: Success;
    error?: Error;
  };
  export type ExtractParams<C> = C extends Callable ? Parameters<C> : [unknown];
  export type ExtractReturnType<C> = C extends Callable ? ReturnType<C> : never;
  export type MethodType<C extends Callable> =
    ReturnType<C> extends Callable ? (...args: Parameters<ReturnType<C>>) => Promise<LSP.CallbackReturnType> : never;
}
