import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { StyledPanelCellRederer } from '../styled';
export const YearCellRenderer = function (_a) {
    var { date, selectedDate, selectedRange } = _a, props = __rest(_a, ["date", "selectedDate", "selectedRange"]);
    const year = date.toLocaleDateString('default', { year: 'numeric' });
    let selectedDateYear;
    if (selectedRange) {
        let rangeIdx = props.info.range === 'start' ? 0 : 1;
        let d = selectedRange[rangeIdx];
        selectedDateYear = (d || props.info.today).toLocaleDateString('default', {
            year: 'numeric'
        });
    }
    else {
        selectedDateYear = (selectedDate || props.info.today).toLocaleDateString('default', {
            year: 'numeric'
        });
    }
    return _jsx(StyledPanelCellRederer, { selected: year === selectedDateYear, children: year });
};
