import { noop } from 'lodash-es';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { getFirstFocusableElement } from '../dom';
import { usePersistentCallback } from './hooks';
export function useAutoFocus(enabled, container) {
    useEffect(() => {
        if (!enabled)
            return;
        if (container.current) {
            container.current.focus();
        }
    }, [container, enabled]);
}
export const useFocusTrapWithContainer = (enabled, container) => {
    useEffect(() => {
        if (!enabled)
            return;
        const cb = (event) => {
            if (event.relatedTarget === null && (container === null || container === void 0 ? void 0 : container.current)) {
                container === null || container === void 0 ? void 0 : container.current.focus();
            }
        };
        document.addEventListener('focusout', cb);
        return () => {
            document.removeEventListener('focusout', cb);
        };
    }, [enabled, container]);
};
export function useFocusTrap(enabled) {
    const container = useRef(null);
    useFocusTrapWithContainer(enabled, container);
    return container;
}
export function useFocusHandleOnClose(open) {
    const focusedElm = useRef(null);
    useEffect(() => {
        if (open) {
            focusedElm.current = document.activeElement;
        }
    }, [open]);
    const preventOnCloseFocus = useCallback((event) => {
        var _a;
        if (focusedElm.current && document.body.contains(focusedElm.current)) {
            focusedElm.current.focus();
        }
        else {
            (_a = getFirstFocusableElement()) === null || _a === void 0 ? void 0 : _a.focus();
            event.preventDefault();
        }
    }, []);
    return preventOnCloseFocus;
}
// If a component is unmounted before the focus event get a chance to fire, it will not fire the blur event,
// Till React 18.0.0, react was triggering the blur event when component is unmounted, but they made it consistent to browser behavior.
// As we do some of the state change on blur, we need to manually trigger the state change when element is getting unmounted.
// This will not get called if the unmount is happening after focus has changed and blur was captured, which is expected behavior.
export function useForceBlurOnUnmount(elementRef, onBlur) {
    const persistentOnBlur = usePersistentCallback(onBlur);
    useLayoutEffect(() => {
        const element = elementRef.current;
        return () => {
            if (document.activeElement instanceof HTMLElement && (element === null || element === void 0 ? void 0 : element.contains(document.activeElement))) {
                const mockedEvent = {
                    relatedTarget: document.body,
                    target: document.activeElement,
                    currentTarget: element,
                    bubbles: true,
                    cancelable: true,
                    defaultPrevented: false,
                    eventPhase: 0,
                    isTrusted: true,
                    timeStamp: 0,
                    type: 'blur',
                    preventDefault: noop,
                    stopPropagation: noop,
                    persist: noop,
                    isDefaultPrevented: () => false,
                    isPropagationStopped: () => false,
                    nativeEvent: {
                        relatedTarget: document.body,
                        target: document.activeElement
                    }
                };
                persistentOnBlur(mockedEvent);
            }
        };
    }, [elementRef, persistentOnBlur]);
}
