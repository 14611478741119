import { jsx as _jsx } from "react/jsx-runtime";
import { getTimezoneOffset } from '@prophecy/utils/date';
import { Select } from '../Select';
const buildTimeZones = () => {
    const zones = Intl.supportedValuesOf('timeZone');
    return zones
        .map((zone) => {
        const offset = getTimezoneOffset(zone);
        const [hours = 0, mins = 0] = offset.split(':').map(Number);
        return {
            value: zone,
            label: `(GMT${offset}) ${zone.replace(/_/g, ' ')}`,
            offsetInMin: (Math.abs(hours) * 60 + mins) * (hours ? hours / Math.abs(hours) : 1)
        };
    })
        .sort((a, b) => a.offsetInMin - b.offsetInMin);
};
const timeZoneList = buildTimeZones();
export const TimezonePicker = (props) => {
    return (_jsx(Select, Object.assign({}, props, { showSearch: true, children: timeZoneList.map((timezone) => {
            const { value, label } = timezone;
            return (_jsx(Select.Option, Object.assign({}, timezone, { children: label }), value));
        }) })));
};
