import { jsx as _jsx } from "react/jsx-runtime";
import utilify from '@prophecy/utils/react/utilify';
import styled from 'styled-components';
import { SIDE_NAVBAR_WIDTH } from '../Layout';
import { Spinner } from './Spinner';
import { tokens } from './tokens';
const StyledSpinner = styled(Spinner) `
  position: fixed;
  z-index: ${tokens.OverlaySpinner.zIndex};
  top: 0;
  left: ${({ allowSideBar, leftOffset = 0 }) => allowSideBar ? parseInt(SIDE_NAVBAR_WIDTH) + leftOffset : leftOffset}px;
  right: 0;
  bottom: 0;
  pointer-events: auto;
`;
export const OverlaySpinner = (props) => {
    return (_jsx(StyledSpinner, Object.assign({ spinning: true }, props, { "data-test-id": 'global-spinner', onClick: (e) => e.stopPropagation(), onPointerDown: (e) => e.stopPropagation(), children: _jsx("div", {}) })));
};
const overlaySpinner = utilify(({ render, unmount }) => {
    const show = (props = {}) => {
        render(_jsx(OverlaySpinner, Object.assign({}, props)));
    };
    const hide = () => {
        unmount();
    };
    return {
        show,
        hide
    };
})({});
OverlaySpinner.show = overlaySpinner.show;
OverlaySpinner.hide = overlaySpinner.hide;
