import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { rotate } from '../theme/animation';
import { tokens, ICON_CLASS } from './tokens';
export var IconVariant;
(function (IconVariant) {
    IconVariant["light"] = "light";
    IconVariant["bold"] = "bold";
    IconVariant["custom"] = "custom";
})(IconVariant || (IconVariant = {}));
export var NewIconVariant;
(function (NewIconVariant) {
    NewIconVariant["default"] = "default";
    NewIconVariant["solid"] = "solid";
    NewIconVariant["mini"] = "mini";
    NewIconVariant["extended"] = "extended";
})(NewIconVariant || (NewIconVariant = {}));
export var IconSize;
(function (IconSize) {
    IconSize["xxxs"] = "xxxs";
    IconSize["xxs"] = "xxs";
    IconSize["xs"] = "xs";
    IconSize["s"] = "s";
    IconSize["m"] = "m";
    IconSize["l"] = "l";
})(IconSize || (IconSize = {}));
export function IconBase(_a) {
    var { iconVariants, type, rotate, name } = _a, restProps = __rest(_a, ["iconVariants", "type", "rotate", "name"]);
    const Component = iconVariants[type];
    if (!Component)
        throw new Error(`Icon type "${type}" is not defined for "${name}"`);
    return _jsx(Component, Object.assign({ rotate: rotate }, restProps, { "data-icon-name": name }));
}
export const Icon = styled(IconBase) `
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
  font-size: ${({ size }) => tokens.Icon[size !== null && size !== void 0 ? size : 's'].size};
  ${(props) => props.rotate && `transform: rotate(${props.rotate}deg);`}
  transform-origin: center center;
  ${(props) => props.spin &&
    css `
      animation: 1s ${rotate} linear infinite;
    `}
  ${(props) => props.color && `color: ${props.color};`}

  /** This is temporarily for the DS2.0, in DS3.0 this is handled */
  ${({ strokeBased }) => strokeBased &&
    css `
      path {
        stroke: currentColor;
        fill: none;
      }
    `}
`;
export function createIcons(name, iconVariants) {
    const CreatedIcon = forwardRef((props, ref) => {
        const type = props.type; //?? getDefaultType(props.type, CreatedIcon.supportedTypes);
        return (_jsx(Icon, Object.assign({}, props, { className: `${ICON_CLASS} ${props.className}`, ref: ref, type: type, iconVariants: iconVariants, name: name })));
    });
    // added this line for typing support
    CreatedIcon.supportedTypes = [];
    return CreatedIcon;
}
export const IconWithUrl = styled.div `
  background-color: ${({ color }) => color};
  mask: url(${({ icon }) => icon});
  mask-size: ${({ size }) => `${size} ${size}`};
  mask-position: center;
  width: ${({ size }) => `${size}`};
  height: ${({ size }) => `${size}`};
`;
