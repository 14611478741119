import { startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, subDays, subMonths, subYears } from 'date-fns';
import { DATE_FORMAT, TIME_FORMAT } from './types';
export const getDefaultPresets = () => [
    {
        label: 'Today',
        value: (() => {
            const startOfDayValue = startOfDay(new Date());
            return [startOfDayValue, undefined];
        })()
    },
    {
        label: 'Yesterday',
        value: (() => {
            const yesterday = subDays(new Date(), 1);
            const startOfYesterday = startOfDay(yesterday);
            return [startOfYesterday, undefined];
        })()
    },
    {
        label: 'This week',
        value: (() => {
            const startOfWeekValue = startOfWeek(new Date(), { weekStartsOn: 0 }); // Sunday as the first day of the week
            const endOfWeekValue = endOfWeek(new Date(), { weekStartsOn: 0 });
            return [startOfWeekValue, endOfWeekValue];
        })()
    },
    {
        label: 'Last week',
        value: (() => {
            const lastWeekStart = startOfWeek(subDays(new Date(), new Date().getDay() + 7), { weekStartsOn: 0 });
            const lastWeekEnd = endOfWeek(subDays(new Date(), new Date().getDay() + 7), { weekStartsOn: 0 });
            return [lastWeekStart, lastWeekEnd];
        })()
    },
    {
        label: 'This month',
        value: (() => {
            const startOfMonthValue = startOfMonth(new Date());
            const endOfMonthValue = endOfMonth(new Date());
            return [startOfMonthValue, endOfMonthValue];
        })()
    },
    {
        label: 'Last month',
        value: (() => {
            const lastMonth = subMonths(new Date(), 1);
            const startOfLastMonth = startOfMonth(lastMonth);
            const endOfLastMonth = endOfMonth(lastMonth);
            return [startOfLastMonth, endOfLastMonth];
        })()
    },
    {
        label: 'This year',
        value: (() => {
            const startOfYearValue = startOfYear(new Date());
            const endOfYearValue = endOfYear(new Date());
            return [startOfYearValue, endOfYearValue];
        })()
    },
    {
        label: 'Last year',
        value: (() => {
            const lastYear = subYears(new Date(), 1);
            const startOfLastYear = startOfYear(lastYear);
            const endOfLastYear = endOfYear(lastYear);
            return [startOfLastYear, endOfLastYear];
        })()
    },
    {
        label: 'All time',
        value: (() => {
            return [new Date(0), new Date()]; // Unix epoch start to now
        })()
    }
];
export const DEFAULT_DATE_FORMAT = DATE_FORMAT.DD_MM_YYYY;
export const DEFAULT_TIME_FORMAT = TIME_FORMAT.HH_MM_SS;
