import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { DiffEditor as MonacoDiffEditor, useMonaco } from '@monaco-editor/react';
import { useRef, useState } from 'react';
import { defaultOptions } from './BaseEditor/base/Editor';
import { useValue } from './editorHooks';
import { DiffEditorContainer } from './styled';
export function DiffEditor(_a) {
    var { onModifiedChange, onModifiedBlur, delay, modified: _modified = '', readOnly = false, options = {} } = _a, props = __rest(_a, ["onModifiedChange", "onModifiedBlur", "delay", "modified", "readOnly", "options"]);
    const [modifiedEditor, setModifiedEditor] = useState();
    const monaco = useMonaco();
    const containerRef = useRef(null);
    const { value: modified, handleChange, handleBlur } = useValue({
        getValue: () => modifiedEditor === null || modifiedEditor === void 0 ? void 0 : modifiedEditor.getValue(),
        value: _modified,
        onChange: onModifiedChange,
        onBlur: onModifiedBlur,
        delay,
        containerRef: containerRef
    });
    function onMount(editor) {
        const modifiedEditor = editor.getModifiedEditor();
        setModifiedEditor(modifiedEditor);
        modifiedEditor.onDidChangeModelContent((_) => {
            handleChange(modifiedEditor.getValue());
        });
        modifiedEditor.onDidBlurEditorWidget(() => {
            handleBlur();
        });
    }
    return (_jsx(DiffEditorContainer, { height: props.height, ref: containerRef, children: _jsx(MonacoDiffEditor, Object.assign({}, props, { options: Object.assign(Object.assign(Object.assign(Object.assign({}, defaultOptions), { renderOverviewRuler: false, lightbulb: {
                    enabled: monaco === null || monaco === void 0 ? void 0 : monaco.editor.ShowLightbulbIconMode.Off
                } }), options), { readOnly }), modified: modified, onMount: onMount })) }));
}
