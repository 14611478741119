import { theme } from '../theme';
// import { theme as Theme } from '../../../ui-v3/src/theme/theme'
export const PREFIX_CLASS = 'ui-picker';
export const TIME_PANEL_CLASS = `${PREFIX_CLASS}-time-panel`;
export const HEADER_CLASS = `${PREFIX_CLASS}-header`;
export const HEADER_VIEW_CLASS = `${PREFIX_CLASS}-header-view`;
export const CONTENT_CLASS = `${PREFIX_CLASS}-content`;
export const TIME_PANEL_COLUMN_CLASS = `${PREFIX_CLASS}-time-panel-column`;
export const TIME_PANEL_CELL_INNER_CLASS = `${PREFIX_CLASS}-time-panel-cell-inner`;
export const TIME_PANEL_CELL_SELECTED_CLASS = `${PREFIX_CLASS}-time-panel-cell-selected`;
export const RANGES_CLASS = `${PREFIX_CLASS}-ranges`;
export const PANEL_LAYOUT_CLASS = `${PREFIX_CLASS}-panel-layout`;
export const PANELS_CLASS = `${PREFIX_CLASS}-panels`;
export const PANEL_CLASS = `${PREFIX_CLASS}-panel`;
export const HEADER_SUPER_NEXT_BTN_CLASS = `${PREFIX_CLASS}-header-super-next-btn`;
export const HEADER_SUPER_PREV_BTN_CLASS = `${PREFIX_CLASS}-header-super-prev-btn`;
export const HEADER_PREV_BTN_CLASS = `${PREFIX_CLASS}-header-prev-btn`;
export const HEADER_NEXT_BTN_CLASS = `${PREFIX_CLASS}-header-next-btn`;
export const DATE_PANEL_CLASS = `${PREFIX_CLASS}-date-panel`;
export const BODY_CLASS = `${PREFIX_CLASS}-body`;
export const CELL_RANGE_START_CLASS = `${PREFIX_CLASS}-cell-range-start`;
export const CELL_RANGE_END_CLASS = `${PREFIX_CLASS}-cell-range-end`;
export const CELL_IN_RANGE_CLASS = `${PREFIX_CLASS}-cell-in-range`;
export const CELL_TODAY_CLASS = `${PREFIX_CLASS}-cell-today`;
export const PRESETS_CLASS = `${PREFIX_CLASS}-presets`;
export const FOOTER_CLASS = `${PREFIX_CLASS}-footer`;
export const DROPDOWN_CLASS = `${PREFIX_CLASS}-dropdown`;
export const RANGE_ARROW_CLASS = `${PREFIX_CLASS}-range-arrow`;
export const CELL_IN_VIEW_CLASS = `${PREFIX_CLASS}-cell-in-view`;
export const DATE_RANGE_INPUT_CHILD_INPUT_CLASS = 'date-range-input-child-input';
export const DECADE_BTN_CLASS = `${PREFIX_CLASS}-decade-btn`;
export const DATE_TIME_PANEL_CLASS = `${PREFIX_CLASS}-datetime-panel`;
export const tokens = {
    Container: {
        border: '1px solid #e4e7ec', // Theme.borderColor.secondary
        //boxShadow: `0px 8px 8px -4px var(--ColorsEffectsShadowsshadow-xl023), 0px 20px 24px -4px var(--ColorsEffectsShadowsshadow-xl018)`,
        boxShadow: `0px 8px 8px -4px ${theme.colors.grey900}08, 0px 20px 24px -4px ${theme.colors.grey900}14`, // The,e.
        borderRadius: theme.radius.l,
        background: theme.colors.white
    },
    Calendar: {
        width: '328px',
        padding: `${theme.spaces.x20} ${theme.spaces.x24}`,
        panel: { width: 'auto' },
        Range: {
            Seperator: {
                height: `calc(100% + calc(2 * ${theme.spaces.x20}))`, // adding some extra height to cover whole div
                color: '#e4e7ec' // Theme.borderColor.secondary
            }
        },
        Header: {
            height: theme.sizes.x32,
            NavBtn: {
                height: theme.sizes.x32,
                width: theme.sizes.x32,
                padding: theme.spaces.x6,
                borderRadius: theme.radius.s
            },
            View: {
                width: '130px',
                height: theme.sizes.x24,
                MonthYearContainer: {
                    fontFamily: theme.fontFamily.text,
                    fontSize: '16px', // Theme.text.md.fontSize
                    fontWeight: theme.fontWeight.medium,
                    lineHeight: '20px', // Theme.text.md.lineHeight
                    color: theme.colors.grey700
                }
            }
        },
        body: {
            width: '280px',
            height: '304px',
            ContentTable: {
                Cells: {
                    // both header and body cells
                    height: theme.sizes.x40,
                    width: theme.sizes.x40,
                    SelectedRange: {
                        backgroundColor: '#F2F4F7' // Theme.backgroundColor.tertiary
                    }
                },
                HeaderCells: {
                    fontWeight: 450, // Theme.fontWeight.medium
                    color: theme.colors.grey700,
                    fontFamily: theme.fontFamily.text,
                    fontSize: '14px', // Theme.text.sm.fontSize
                    lineHeight: '18px' // Theme.text.sm.lineHeight
                }
            }
        }
    },
    DateCell: {
        height: theme.sizes.x40,
        width: theme.sizes.x40,
        fontWeight: 450, // Theme.fontWeight.medium
        color: theme.colors.grey700,
        fontFamily: theme.fontFamily.text,
        fontSize: '14px', // Theme.text.sm.fontSize
        lineHeight: '18px', // Theme.text.sm.lineHeight,
        Selected: {
            backgroundColor: theme.colors.primary600,
            color: theme.colors.white
        },
        HasEvents: {
            backgroundColor: theme.colors.primary600,
            color: theme.colors.grey100,
            notInViewColor: theme.colors.grey500,
            Dot: {
                backgroundColor: theme.colors.primary600,
                notInViewBackgroundColor: theme.colors.grey500
            },
            Selected: {
                backgroundColor: theme.colors.primary600,
                color: theme.colors.white,
                Dot: {
                    color: theme.colors.white
                }
            }
        },
        States: {
            Hovered: {
                backgroundColor: theme.colors.primary100,
                color: '#182230' // Theme.colors.gray800
            }
        },
        NotInView: {
            color: theme.colors.grey500,
            fontWeight: theme.fontWeight.normal,
            HasEvents: {
                backgroundColor: theme.colors.white,
                Dot: {
                    backgroundColor: theme.colors.grey400
                }
            }
        },
        Range: {
            color: '#E4E7EC'
        }
    },
    ShowIncludeTime: {
        padding: `${theme.spaces.x16} ${theme.spaces.x24}`,
        gap: theme.spaces.x16,
        borderTop: '1px solid #E4E7EC', // Theme.colors.gray200
        fontFamily: theme.fontFamily.text,
        fontSize: '16px', // Theme.text.md.fontSize
        fontWeight: 450, // Theme.fontWeight.medium
        lineHeight: '20px', // Theme.text.md.lineHeight
        color: theme.colors.grey700
    },
    PickerAction: {
        padding: theme.spaces.x16,
        gap: theme.spaces.x12,
        borderTop: '1px solid #e4e7ec', // Theme.borderColor.secondary`,
        Button: {
            padding: `${theme.spaces.x10} ${theme.spaces.x14}`,
            gap: theme.spaces.x4,
            borderRadius: theme.radius.m,
            fontFamily: theme.fontFamily.text,
            fontSize: '14px', // Theme.text.sm.fontSize,
            fontWeight: 550, // Theme.fontWeight.semiBold
            lineHeight: '18px', // Theme.text.sm.lineHeight,
            boxShadow: `0px 1px 2px 0px ${theme.colors.grey900}0D,
                0px -2px 0px 0px ${theme.colors.grey900}0D inset,
                0px 0px 0px 1px  ${theme.colors.grey900}2E inset;`,
            Cancel: {
                color: theme.colors.grey700,
                border: `1px solid ${theme.colors.grey300}`
            },
            Apply: {
                backgroundColor: theme.colors.primary600,
                borderImageSource: 'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);'
            }
        }
    },
    StyledDateRangePickerContainer: {
        border: '1px solid #e4e7ec', // Theme.borderColor.secondary
        //boxShadow: `0px 8px 8px -4px var(--ColorsEffectsShadowsshadow-xl023), 0px 20px 24px -4px var(--ColorsEffectsShadowsshadow-xl018)`,
        boxShadow: `0px 8px 8px -4px ${theme.colors.grey900}08, 0px 20px 24px -4px ${theme.colors.grey900}14`, // The,e.
        borderRadius: theme.radius.l,
        background: theme.colors.white,
        height: '376px',
        ActionContainer: {
            borderTop: '1px solid #e4e7ec' // Theme.borderColor.secondary`,
        }
    },
    RangeCalendarContainer: {
        borderTop: '1px solid #e4e7ec' // Theme.borderColor.secondary`,
    },
    Presets: {
        width: 192,
        height: '448px',
        padding: `${theme.spaces.x12} ${theme.spaces.x16}`,
        gap: theme.spaces.x4,
        borderRight: '.5px solid #e4e7ec', // Theme.borderColor.secondary`,
        Items: {
            padding: `${theme.spaces.x8} ${theme.spaces.x16}`,
            borderRadius: theme.radius.s,
            fontFamily: theme.fontFamily.text,
            fontSize: '14px', // Theme.text.sm.fontSize,
            fontWeight: 450, // Theme.fontWeight.medium
            lineHeight: '20px', //  Theme.text.sm.lineHeight,
            color: theme.colors.grey700,
            States: {
                Hover: {
                    backgroundColor: theme.colors.grey100
                }
            }
        }
    },
    TimePickerContainer: {
        Panel: {
            border: '.5px solid #e4e7ec', // Theme.borderColor.secondary
            //boxShadow: `0px 8px 8px -4px var(--ColorsEffectsShadowsshadow-xl023), 0px 20px 24px -4px var(--ColorsEffectsShadowsshadow-xl018)`,
            boxShadow: `0px 8px 8px -4px ${theme.colors.grey900}08, 0px 20px 24px -4px ${theme.colors.grey900}14`, // The,e.
            borderRadius: theme.radius.l,
            background: theme.colors.white,
            padding: `${theme.spaces.x20} ${theme.spaces.x24}`,
            Header: {
                marginBottom: theme.spaces.x8,
                fontFamily: theme.fontFamily.text,
                fontSize: '16px', // Theme.text.md.fontSize
                fontWeight: theme.fontWeight.medium,
                lineHeight: '20px', // Theme.text.md.lineHeight
                color: theme.colors.grey700
            },
            Cells: {
                height: theme.sizes.x40,
                width: theme.sizes.x40,
                fontFamily: theme.fontFamily.text,
                fontSize: '14px', // Theme.text.sm.fontSize,
                fontWeight: 450, // Theme.fontWeight.medium
                lineHeight: '20px', //  Theme.text.sm.lineHeight,
                color: theme.colors.grey700,
                States: {
                    Hovered: {
                        backgroundColor: theme.colors.primary100,
                        color: '#182230' // Theme.colors.gray800
                    }
                }
            },
            SelectedCells: {
                backgroundColor: theme.colors.primary600,
                color: theme.colors.white
            }
        }
    }
};
