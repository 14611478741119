import { KeyCodes } from './keyboard';
export function htmlToDOMElement(html) {
    var template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild;
}
export function htmlToSVGDOMElement(html) {
    var _a;
    var template = document.createElement('template');
    html = html.trim();
    template.innerHTML = `<svg>${html}</svg>`;
    return (_a = template.content.firstChild) === null || _a === void 0 ? void 0 : _a.firstChild;
}
export function getTranslateXY(element) {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return {
        x: matrix.m41,
        y: matrix.m42
    };
}
export function waitForElement(selector, root, timeout) {
    return new Promise((resolve, reject) => {
        let start = performance.now();
        function checkForElement() {
            const element = root.querySelector(selector);
            if (element) {
                resolve(element);
            }
            else if (typeof timeout === 'number' && performance.now() - start > timeout) {
                reject(new Error(`Element with selector ${selector} was not found in the DOM after ${timeout}ms`));
            }
            else {
                requestAnimationFrame(checkForElement);
            }
        }
        checkForElement();
    });
}
export const preventDefault = (e) => {
    e.preventDefault();
};
export const stopPropagation = (e) => {
    e.stopPropagation();
};
export const onEnter = (callback) => (e) => {
    if (e.key === KeyCodes.Enter) {
        callback === null || callback === void 0 ? void 0 : callback(e);
    }
};
export const getFirstFocusableElement = (parent = document.body) => {
    const noAutoFocusAttr = '[data-no-autofocus]';
    return parent.querySelector(`button:not(${noAutoFocusAttr}) , [href]:not(${noAutoFocusAttr}), input:not(${noAutoFocusAttr}), select:not(${noAutoFocusAttr}), textarea:not(${noAutoFocusAttr}), [tabindex]:not([tabindex="-1"]):not(${noAutoFocusAttr})`);
};
export const preventAutoCloseTriggerFocus = (event) => {
    var _a;
    (_a = getFirstFocusableElement()) === null || _a === void 0 ? void 0 : _a.focus();
    event.preventDefault();
};
export const afterAnimationFrame = (callback) => {
    requestAnimationFrame(() => {
        setTimeout(callback);
    });
};
export function isTextSelected() {
    var selection = window.getSelection();
    return selection && selection.type === 'Range';
}
export function isTextBoxActive() {
    var _a;
    return (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.matches('input,textarea');
}
export function clearWindowSelection() {
    const selection = window.getSelection();
    if (selection) {
        if (selection.empty) {
            // Chrome
            selection.empty();
        }
        else if (selection.removeAllRanges) {
            // Firefox
            selection.removeAllRanges();
        }
    }
}
export const closest = (element, matcher) => {
    let node = element;
    while (node && !matcher(node))
        node = node === null || node === void 0 ? void 0 : node.parentElement;
    return node;
};
