import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled from 'styled-components';
import { Icon, EditBIcon, LoadingDIcon } from '../Icons';
import { growInputCss } from './InlineInput';
import { Input } from './Input';
import { InputWrap, StyledInputContainer } from './styled';
import { tokens } from './tokens';
const StyledGhostInput = styled(Input) `
  ${StyledInputContainer} {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid ${tokens.GhostInput.borderColor};
    border-radius: ${tokens.GhostInput.borderRadius};
    box-shadow: none;
    padding: ${(props) => tokens.GhostInput.padding};
    padding-top: 0;
    font-weight: ${tokens.GhostInput.fontWeight};
    font-size: ${(props) => tokens.GhostInput.size[props.inputSize].fontSize};
    height: ${(props) => tokens.GhostInput.size[props.inputSize].height};
    gap: ${(props) => tokens.GhostInput.size[props.inputSize].gap};
    color: ${tokens.GhostInput.color};
    padding-right: ${tokens.GhostInput.iconSize};
    ${Icon} {
      display: none;
    }
    &:focus-within {
      color: ${tokens.GhostInput.focus.color};
      border-bottom: 1px solid ${tokens.GhostInput.focus.borderColor};
    }

    &:hover,
    &:focus-within {
      padding-right: 0;
      ${Icon} {
        display: block;
      }
    }

    ${({ $loading }) => $loading && `${Icon} { display: block; }`}
  }
  ${growInputCss}
  ${InputWrap} {
    &:not([data-value='']) {
      color: ${tokens.GhostInput.focus.color};
    }
  }
`;
export const GhostInput = forwardRef((_a, ref) => {
    var { inputSize, loading } = _a, restProps = __rest(_a, ["inputSize", "loading"]);
    return (_jsx(StyledGhostInput, Object.assign({ size: 1, inputSize: inputSize, icon: loading ? (_jsx(LoadingDIcon, { spin: true, size: 'xs', type: 'extended', color: tokens.GhostInput.loading.iconColor })) : (_jsx(EditBIcon, { type: 'default', color: tokens.GhostInput.iconColor })), iconPlacement: 'right', "$loading": loading, ref: ref }, restProps)));
});
