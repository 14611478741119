import { Callable, TimeoutId } from '@prophecy/interfaces/generic';
import { toast } from '@prophecy/ui/Toast';
import { ToastUtilReturnType } from '@prophecy/ui/Toast/types';

const ToastInterval = 25 * 1000; // 25 sec

export class KeepAliveClient {
  private _retryToastTimeout?: TimeoutId;

  private _retryToast?: ToastUtilReturnType;

  private _showToast?: boolean;

  private pongCallback: Callable;

  public preventDisconnect?: boolean;

  constructor(pongCallback: Callable) {
    this.pongCallback = pongCallback;
  }

  private clearToast() {
    if (this._retryToast) {
      this._retryToast.close();
    }
    if (this._retryToastTimeout) clearTimeout(this._retryToastTimeout);
  }

  private showToast() {
    /**
     * this is just for notifying purpose, we don't automatically retry anything as such.
     * This is to handle the case where backend is closed, but from the frontend perspective the
     * socket is still up. In this case ping will not come from backend.
     * The socket timeout after 1-2 min. so in between user will see this toast
     */
    this._retryToastTimeout = setTimeout(() => {
      if (!document.hidden && this._showToast) {
        this._retryToast = toast.loading({
          content: 'Retrying to connect IDE.',
          placement: 'bottom',
          closeable: false
        });
      }
    }, ToastInterval);
  }

  private visibilityChange = () => {
    // we need to refresh token when ever there visibility change visible -> hidden or hidden to active.
    // visible -> hidden is required so that we make sure, token is not expired just after the tab change
    // hidden -> visible is required so that we can check if token is valid
    this.sendPong();
  };

  private sendPong() {
    this.pongCallback();
  }

  public init(showToast = true) {
    this._showToast = showToast;
    document.addEventListener('visibilitychange', this.visibilityChange);
  }

  public onPing() {
    this.clearToast();

    if (!document.hidden || this.preventDisconnect) {
      this.showToast();
      this.sendPong();
    }
  }

  public onClose() {
    document.removeEventListener('visibilitychange', this.visibilityChange);

    this.clearToast();
  }
}
