import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Empty } from '../Empty';
import { Tree } from '../Tree';
import { TREE_ICON_CUSTOMIZE_CLASS, TREE_ICON_ELEMENT_CLASS } from '../Tree/tokens';
import { getURIParts, getFileIcon, addLeadingSlash } from './utils';
export const FileTreeCSS = css `
  .folder-node {
    .${TREE_ICON_ELEMENT_CLASS}.${TREE_ICON_CUSTOMIZE_CLASS} {
      display: none;
    }
  }
`;
const StyledTree = styled(Tree) `
  ${FileTreeCSS}
`;
export function getTreeData(files, { expandDotFolder, traversers }) {
    const treeData = [];
    const expandableKeys = [];
    const tempTree = {
        map: {},
        children: treeData
    };
    files.forEach((path, fileIndex) => {
        const parts = getURIParts(path);
        const lastIndex = parts.length - 1;
        parts.reduce((node, part, index) => {
            let childNode = node.map[part];
            // if a node is already added no need to add it again
            if (!childNode) {
                childNode = { map: {}, children: [] };
                let key = parts.slice(0, index + 1).join('/');
                //If path starts with '/' then key also should start with slash to make the path same for parent nodes
                if (path.startsWith('/')) {
                    key = addLeadingSlash(key);
                }
                let treeNode = {
                    title: part,
                    key: key,
                    children: childNode.children,
                    icon: ' '
                };
                // add leafNode information
                if (index === lastIndex) {
                    treeNode.icon = getFileIcon(part);
                    treeNode.isLeaf = true;
                    // for leaf node use path as key, which we can use to extract path from tree node
                    treeNode.key = path;
                }
                else {
                    treeNode.className = 'folder-node';
                    // push non leaf key as expandable key as only those can be expanded
                    if (expandDotFolder) {
                        expandableKeys.push(key);
                    }
                    else {
                        // avoid expanding dot folders
                        if (!part.startsWith('.')) {
                            expandableKeys.push(key);
                        }
                    }
                }
                // store childnode on node map, so we can use it for next call
                node.map[part] = childNode;
                // push the tree node on children
                node.children.push(treeNode);
            }
            return childNode;
        }, tempTree);
    });
    traverseTree(treeData, ...traversers.filter(Boolean));
    return { treeData, expandableKeys };
}
function executeTraversers(traversers, node, isDirectory) {
    traversers.forEach((traverser) => traverser(node, isDirectory));
}
export function traverseTree(tree, ...traversers) {
    tree.forEach((node) => {
        var _a;
        if ((_a = node.children) === null || _a === void 0 ? void 0 : _a.length) {
            executeTraversers(traversers, node, true);
            traverseTree(node.children || [], ...traversers);
        }
        else {
            executeTraversers(traversers, node, false);
        }
    });
}
export function mergeSingleDirectoryFolders(node, isDirectory) {
    var _a;
    if (!isDirectory)
        return;
    const titles = [node.title];
    let currentNode, lastNode;
    currentNode = lastNode = node;
    while (((_a = currentNode.children) === null || _a === void 0 ? void 0 : _a.length) === 1) {
        currentNode = currentNode.children[0];
        if (currentNode.isLeaf)
            break;
        lastNode = currentNode;
        titles.push(currentNode.title);
    }
    if (currentNode !== node) {
        const title = titles.join('/');
        Object.assign(node, {
            title,
            key: lastNode.key,
            children: lastNode.children
        });
    }
}
export function getExpandedKeys(expandedPaths) {
    const expandedKeys = new Set();
    expandedPaths.forEach((file) => {
        const parts = getURIParts(file);
        parts.forEach((part, index) => {
            const key = parts.slice(0, index + 1).join('/');
            expandedKeys.add(key);
        }, '');
    });
    return Array.from(expandedKeys);
}
export function sortFiles(treeData) {
    treeData.sort((a, b) => {
        if (a.children.length && !b.children.length) {
            return -1;
        }
        else if (!a.children.length && b.children.length) {
            return 1;
        }
        else {
            return a.title.localeCompare(b.title);
        }
    });
    // short child nodes if any
    treeData.forEach((node) => {
        if (node.children) {
            sortFiles(node.children);
        }
    });
}
export default function FileTree(_a) {
    var { files, prepareNode = () => { }, editable = false, onChange, onFileSelect, expandedPaths = [], expandAll, expandDotFolder = false, enableSort = true, wrapFolders = false } = _a, restProps = __rest(_a, ["files", "prepareNode", "editable", "onChange", "onFileSelect", "expandedPaths", "expandAll", "expandDotFolder", "enableSort", "wrapFolders"]);
    const { treeData, expandableKeys } = useMemo(() => getTreeData(files, {
        expandDotFolder,
        traversers: [prepareNode, wrapFolders ? mergeSingleDirectoryFolders : undefined]
    }), [files, prepareNode, wrapFolders, expandDotFolder]);
    const defaultExpandedKeys = useMemo(() => getExpandedKeys(expandedPaths), [expandedPaths]);
    const [expandedKeys, setExpandedKeys] = useState(defaultExpandedKeys);
    // short inplace if treeData changed
    useMemo(() => {
        if (enableSort) {
            sortFiles(treeData);
        }
    }, [treeData, enableSort]);
    useEffect(() => {
        if (expandAll) {
            setExpandedKeys(expandableKeys);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandableKeys.join(), expandAll]);
    if (!files.length)
        return _jsx(Empty, { description: 'No matching results' });
    return (_jsx(StyledTree, Object.assign({}, restProps, { showIcon: true, treeData: treeData, expandedKeys: expandedKeys, onExpand: setExpandedKeys, selectable: true, onSelect: (selectedKeys, info) => {
            var _a;
            const key = info.node.key;
            // if its a directory expand the directory
            if ((_a = info.node.children) === null || _a === void 0 ? void 0 : _a.length) {
                // toggle the expanded key
                if (expandedKeys.includes(key)) {
                    setExpandedKeys(expandedKeys.filter((expandedKey) => expandedKey !== key));
                }
                else {
                    setExpandedKeys([...expandedKeys, key]);
                }
            }
            else {
                onFileSelect(key);
            }
        } })));
}
