import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { parse, format } from 'date-fns';
import { useMemo, useRef } from 'react';
import { CalendarIcon, MinusIcon } from '../../Icons/index';
import { Input } from '../../Input/index';
import { StyledInputRoot, StyledInputContainer } from '../../Input/styled';
import { Popover } from '../../Popover/index';
import { DEFAULT_DATE_FORMAT } from '../constant';
import { useInternalValue, usePickerInput } from '../hooks';
import { DateRangePicker } from '../pickerVariants/DateRangePicker';
import { StyledDateRangeInput } from '../styled';
import { DATE_RANGE_INPUT_CHILD_INPUT_CLASS } from '../tokens';
import { PICKER_VARIANT } from '../types';
import { isDateValid } from '../utils/utils';
export const DateRangeInput = function (_a) {
    var { dateFormat = DEFAULT_DATE_FORMAT, popoverProps, showActionFooter = false, pickerProps, timeFormat } = _a, props = __rest(_a, ["dateFormat", "popoverProps", "showActionFooter", "pickerProps", "timeFormat"]);
    const { value: val, onChange: setVal, sync, reset } = useInternalValue({
        value: props.value,
        onChange: props.onChange,
        autoSync: !showActionFooter
    });
    const { popoverOpen, containerRef, iconRef: calendarIconContainerRef, handleSelect, handlePopupOutsideInterraction, onApply, onCancel } = usePickerInput({
        format: dateFormat,
        onChange: setVal,
        isValid: (val, format) => isDateValid(val, format),
        sync,
        reset
    });
    const getRangeString = usePersistentCallback(function (dateRange) {
        return [dateRange[0] ? format(dateRange[0], dateFormat) : '', dateRange[1] ? format(dateRange[1], dateFormat) : ''];
    });
    const inputString = useMemo(() => getRangeString(val), [getRangeString, val]);
    const startInputRef = useRef(null);
    const endInputRef = useRef(null);
    const pickerRef = useRef(null);
    function getNativePickerInputElements() {
        var _a, _b;
        return (_b = (_a = pickerRef.current) === null || _a === void 0 ? void 0 : _a.getInputElements()) !== null && _b !== void 0 ? _b : [null, null];
    }
    const handleChange = function (strVal, isStartDate = false) {
        const isValid = isDateValid(strVal, dateFormat);
        if (isValid) {
            const parsedDate = parse(strVal, dateFormat, new Date());
            setVal(isStartDate ? [parsedDate, val === null || val === void 0 ? void 0 : val[1]] : [val === null || val === void 0 ? void 0 : val[0], parsedDate]);
        }
    };
    function handleInputClick(isStart = false) {
        var _a, _b, _c;
        const nativePickerInputs = getNativePickerInputElements();
        const clickIndex = isStart ? 0 : 1;
        (_a = nativePickerInputs[clickIndex]) === null || _a === void 0 ? void 0 : _a.focus();
        isStart ? (_b = startInputRef.current) === null || _b === void 0 ? void 0 : _b.focus() : (_c = endInputRef.current) === null || _c === void 0 ? void 0 : _c.focus();
    }
    return (_jsx(StyledDateRangeInput, { ref: containerRef, children: _jsx(StyledInputRoot, { variant: 'primary', size: 'l', children: _jsxs(StyledInputContainer, { type: '', variant: 'primary', inputSize: 'l', children: [_jsx(Input, Object.assign({}, props, { value: inputString[0], onChange: (val) => handleChange(val, true), placeholder: dateFormat.toLocaleLowerCase(), iconPlacement: 'right', className: DATE_RANGE_INPUT_CHILD_INPUT_CLASS, size: 10, ref: startInputRef, onClick: () => handleInputClick(true) })), _jsx(MinusIcon, { type: 'default' }), _jsx(Input, Object.assign({}, props, { value: inputString[1], onChange: (val) => handleChange(val), placeholder: dateFormat.toLocaleLowerCase(), iconPlacement: 'right', className: DATE_RANGE_INPUT_CHILD_INPUT_CLASS, size: 10, ref: endInputRef, onClick: () => handleInputClick() })), _jsx(Popover, Object.assign({ visible: popoverOpen, overlay: _jsx(DateRangePicker, Object.assign({}, pickerProps, { value: val, onChange: (val) => handleSelect(val), dateFormat: dateFormat, timeFormat: timeFormat, variant: PICKER_VARIANT.DATE_RANGE, showActionFooter: showActionFooter, onApply: onApply, onCancel: onCancel, ref: pickerRef })), overlayStyle: { border: 'none', boxShadow: 'none', backgroundColor: 'transparent' } }, popoverProps, { onInteractOutside: handlePopupOutsideInterraction, children: _jsx("div", { ref: calendarIconContainerRef, children: _jsx(CalendarIcon, { type: 'default' }) }) }))] }) }) }));
};
