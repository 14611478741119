import { TerminalProcessExecutionStatus } from 'frontend/core/src/common/Job/constants';

import { Alerting } from '../../../common/EntityToCreate/context/types';
import { ExecutionStep } from '../../../common/Execution/types';
import { SchedulerType } from '../../../common/queries/job';
import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  COMMON_ACTION_TYPES,
  Connection,
  GenericGraph,
  Metadata
} from '../../../common/types';
import { ExecutionStatus } from '../../../data/apis/types';
import { DependencyType, Ports, UDFDefinition } from '../../../redux/types';
import { DAGParams } from './dagParams.types';

export enum PermissionLevel {
  CanView = 'CAN_VIEW',
  IsOwner = 'IS_OWNER',
  CanManage = 'CAN_MANAGE',
  CanManageRun = 'CAN_MANAGE_RUN'
}

export type Permission = {
  userName?: string;
  servicePrincipalName?: string;
  groupName?: string;
  permissionLevel: PermissionLevel;
  user_name?: string;
  service_principal_name?: string;
};

export type JobProcess = BaseProcess<BaseProcessMetadata> & {
  ports: Ports;
  group: string;
};

export enum DatabricksProcessKind {
  Pipeline = 'Pipeline',
  Script = 'Script',
  Cluster = 'Cluster',
  Model = 'Model',
  DLTPipeline = 'DLTPipeline'
}

export enum AirflowProcessKind {
  DatabricksPipeline = 'DatabricksPipeline'
}

export enum JobCategory {
  Prophecy = 'Prophecy',
  Operator = 'Operator',
  Sensor = 'Sensor',
  Subgraph = 'Subgraph',
  SparkSQL = 'Spark/SQL',
  TriggerNotify = 'Trigger/Notify',
  Custom = 'Custom',
  DataTransfer = 'Data Transfer'
}

export type AvailableFabricType = {
  id: string;
  name: string;
  trialStartDate?: number;
  clusterSizes: {
    default: string;
    availableClusterSizes: string[];
  };
};

export enum ClusterMode {
  'Single' = 'Single',
  'Multi' = 'Multi'
}

export enum JobSchedulerType {
  MWAA = 'MWAA',
  Astronomer = 'Astronomer',
  Databricks = 'Databricks',
  Composer = 'Composer',
  Prophecy = 'Prophecy',
  OpenSource = 'OpenSource'
}

export type JobMetadata = {
  type: `${JobSchedulerType}`;
  availableFabrics: AvailableFabricType[];
  fabricId: string;
  schedule: string;
  enabled: boolean;
  name: string;
  timeoutSeconds: number;
  defaultClusterSize: string;
  alerting: Alerting;
  airflowFunctions?: UDFDefinition[] | null;
  jobClusterMode?: {
    clusterMode: ClusterMode;
  };
  isAutoLayoutEnabled?: boolean;
  processDefaultSettings: {
    type: string;
    retries: number;
    timeoutSeconds: number;
    alerting: Alerting;

    // Airflow Config
    email: string;
    email_on_failure: boolean;
    email_on_retry: boolean;
    retry_delay: number;
    retry_exponential_backoff: boolean;
    max_retry_delay: number;
    execution_timeout_int: number;
    trigger_rule: string;
    depends_on_past: boolean;
    ignore_first_depends_on_past: boolean;
    wait_for_downstream: boolean;
    priority_weight: number;
    weight_rule: string;
    queue: string;
    pool: string;
    pool_slots: number;
    max_active_tis_per_dag: number;
    do_xcom_push: boolean;
    run_as_user: string;
  };
  runAs?: Permission;
  accessControlList?: Permission[];
  scheduleTimeZone?: string;

  // Airflow config
  maxActiveTasks?: number;
  maxActiveRuns?: number;
  dagPausedUponCreation?: boolean;
  catchup?: boolean;
  tags?: string[];
  startDate?: string | number | null;
  endDate?: string | null;
  dagName?: string;

  dagParams?: DAGParams;
  resourceFolderPath?: string;
};

export type JobGraph = GenericGraph<JobMetadata, BaseProcessMetadata, JobProcess, Connection>;

export type Interim = {
  selectedComponentId?: string;
  interims: {
    [interimKey: string]: Interims | undefined;
  };
};

export type InterimValue = { [key: string]: unknown };
// currently we do not support nested foreach so { [iteratorKey: number]: InterimValue }
// is only possible now but in future if nested foreach is needed we ce can have nested
// iterations
export type InterimIterations = { [iteratorKey: string]: InterimValue | undefined };
export type InterimValues = InterimValue | InterimIterations;

export type InterimData =
  | {
      iteratable: true;
      value: InterimIterations;
    }
  | {
      iteratable: false;
      value: InterimValue;
    };

export type Interims = {
  componentId: string;
  data?: InterimData;
  taskId: string;
  isStale?: boolean;
  status?: { [mapIndex: string]: TerminalProcessExecutionStatus };
};

export interface JobState extends BaseState<JobGraph, Metadata> {
  jobId: string;
  execution: {
    progress: string;
    clusterStatus?: ExecutionStatus;
    status: Omit<ExecutionStatus, ExecutionStatus.NOT_RUNNING>;
    steps: ExecutionStep[];
    configLoaded?: boolean;
    airflowConfig?: AirflowDetails;
    databricksConfig?: DataBricksDetails;
    dltPipelines?: Array<{ id: string; name: string }>;
    interactiveRunClusterId?: string;
    interim: Interim;
    availableFabrics?: AvailableFabricType[];
    oAuthLoginState?: {
      isLoginNeeded?: boolean;
      databricksLogin?: boolean;
      autoLogin?: boolean;
      recheckLogin?: boolean;
    };
  };
  resolvedDependencies?: DependencyType[];
}

export interface UserEntity {
  label: string;
  value: string;
}

export type DataBricksDetails = {
  url: string;
  currentUser?: {
    emails: Array<{
      primary: boolean;
      value: string;
    }>;
  };
  isAdmin?: boolean;
  jobPermissions: Permission[];
};

export interface AirflowDetails {
  url: string;
  dag: {
    next_dagrun: string;
  };
  connections?: Array<{
    connection_id: string;
    conn_type: string;
  }> | null;
  defaultConfig: Partial<JobMetadata>;
}

export enum actionTypes {
  // Logs
  executionStepDetails = 'execution/stepDetails',
  executionStepLog = 'execution/stepLog',
  executionStepStatusUpdate = 'execution/stepStatusUpdate',
  executionStepIterationUpdate = 'execution/stepIterationUpdate',
  executionStepIterationConfig = 'execution/stepIterationConfig',
  executionDiagnostics = 'execution/diagnostics',

  executionClusterStatus = 'execution/clusterStatus',
  executionStepUrl = 'execution/stepUrl',
  executionStatusUpdate = 'execution/statusUpdate',
  executionProgress = 'execution/progress',
  updateDatabricksDetails = 'updateDatabricksDetails',
  resetDatabricksDetails = 'resetDatabricksDetails',
  oAuthLoginState = 'oAuthLoginState',

  setDLTPipelines = 'setDLTPipelines',
  setInteractiveRunClusterId = 'setInteractiveRunClusterId',
  loadInterimData = 'loadInterimData',
  setInterimStatus = 'setInterimStatus',
  setInterimStale = 'setInterimStale',
  setAllInterimsStale = 'setAllInterimsStale',
  showInterimError = 'showInterimError',
  openInterimDataDialog = 'openInterimDataDialog',
  closeInterimDataDialog = 'closeInterimDataDialog'
}

type JobAction =
  | { type: actionTypes.executionStepDetails; payload: ExecutionStep[] }
  | {
      type: actionTypes.executionStepLog;
      payload: { stepId: string; message: string; level: string; mapIndex: number }[];
    }
  | {
      type: actionTypes.executionStepStatusUpdate;
      payload: { stepId: string; status: ExecutionStatus; name: string; mapIndex: number };
    }
  | {
      type: actionTypes.executionStepIterationUpdate;
      payload: { stepId: string; iterations: number };
    }
  | {
      type: actionTypes.executionStepIterationConfig;
      payload: { stepId: string; config: string[]; mapIndex: number };
    }
  | { type: actionTypes.executionStepUrl; payload: { stepId: string; url: string } }
  | { type: actionTypes.executionStatusUpdate; payload?: ExecutionStatus }
  | { type: actionTypes.executionDiagnostics; payload: { level: string; message: string } }
  | { type: actionTypes.executionClusterStatus; payload?: ExecutionStatus }
  | { type: actionTypes.executionProgress; payload: string }
  | { type: actionTypes.setDLTPipelines; payload: Array<{ id: string; name: string }> }
  | {
      type: actionTypes.updateDatabricksDetails;
      payload:
        | {
            type: SchedulerType;
            config: AirflowDetails | DataBricksDetails;
          }
        | undefined;
    }
  | {
      type: actionTypes.resetDatabricksDetails;
    }
  | {
      type: actionTypes.oAuthLoginState;
      payload: {
        isLoginNeeded?: boolean;
        databricksLogin?: boolean;
        autoLogin?: boolean;
        recheckLogin?: boolean;
      };
    }
  | {
      type: actionTypes.setInteractiveRunClusterId;
      payload: string | undefined;
    }
  | {
      type: actionTypes.loadInterimData;
      payload: {
        componentId: string;
        data: InterimValue;
        iteration: number;
        taskId: string;
      };
    }
  | {
      type: actionTypes.showInterimError;
      payload: {
        slug: string;
        error: string;
      };
    }
  | {
      type: actionTypes.openInterimDataDialog;
      payload: {
        componentId: string;
      };
    }
  | {
      type: actionTypes.closeInterimDataDialog;
    }
  | {
      type: actionTypes.setInterimStale;
      payload: {
        componentId: string;
        isStale: boolean;
      };
    }
  | {
      type: actionTypes.setAllInterimsStale;
    }
  | {
      type: actionTypes.setInterimStatus;
      payload: {
        componentId: string;
        status: string;
        mapIndex: string;
      };
    };

export type JOB_ACTION_TYPES = JobAction | COMMON_ACTION_TYPES<JobGraph, Metadata>;

export const JobScheduleNone = 'None';
