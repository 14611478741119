import { SupportedLanguages } from '@prophecy/ui';
import { FileType } from '@prophecy/ui/Editor/types';

import { LSP_SEVERITY_KEYS } from './constants';

export enum ViewType {
  visual = 'visual',
  code = 'code'
}
export enum CodeViewMode {
  single = 'single',
  multiple = 'multiple'
}

export type GenerateCodeRequest = {
  sessionId?: string;
  mode?: string;
  interimMode?: string;
  processId?: string;
  clusterId?: string;
  saveAsTableOptions?: {
    table?: string;
    schema?: string;
    catalog?: string;
  };
};

export type Range = {
  start: {
    line: number;
    character: number;
  };
  end: {
    line: number;
    character: number;
  };
};

export type TextDocument = {
  uri: string;
  text: string;
  fileType?: FileType;
  language?: SupportedLanguages;
  editable?: boolean;
  deletable?: boolean;
};

export type FileDefinitions = {
  uri: string;
  definitions: {
    range: Range;
    referencePath: string;
  }[];
};

export enum DiagnosticType {
  String = 1,
  Markdown = 2
}

export enum DiagnosticFixType {
  CopilotDiagnosticFix = 'CopilotDiagnosticFix',
  ValueDiagnosticFix = 'ValueDiagnosticFix'
}

export enum CopilotFixType {
  expression = 'expression',
  gem = 'gem'
}

export type DiagnosticFix = {
  fixType?: DiagnosticFixType;
  copilotFixType?: CopilotFixType;
};
export type DiagnosticMetadata = {
  isStale?: boolean;
};

export type Diagnostic = {
  property: string;
  severity: LSP_SEVERITY_KEYS;
  range: Range;
  message: string;
  source?: string;
  diagnosticType?: DiagnosticType;
  diagnosticFix?: DiagnosticFix;
  metadata?: DiagnosticMetadata;
};

export interface ListenerConfig<T> {
  options: { latest: boolean };
  callback: Callback<T>;
}
export interface CallbackParams<T> {
  method: T;
  // TODO: type can be fixed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  id?: string;
}
export type Callback<T> = (p: CallbackParams<T>) => void;

export enum MethodCallStatus {
  'started' = 'started',
  'succeeded' = 'succeeded',
  'failed' = 'failed'
}

export type MethodListenerCallback = (params: { status: MethodCallStatus }) => void;
