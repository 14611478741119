import { jsx as _jsx } from "react/jsx-runtime";
import { DateRangePicker } from './pickerVariants/DateRangePicker';
import { DefaultDatePicker } from './pickerVariants/DefaultDatePicker';
import { TimePicker } from './pickerVariants/TimePicker';
import { PICKER_VARIANT } from './types';
export const DatePicker = (props) => {
    switch (props.variant) {
        case PICKER_VARIANT.DATE_RANGE: {
            return _jsx(DateRangePicker, Object.assign({}, props));
        }
        case PICKER_VARIANT.TIME: {
            return _jsx(TimePicker, Object.assign({}, props));
        }
        case PICKER_VARIANT.DATE: {
            return _jsx(DefaultDatePicker, Object.assign({}, props));
        }
    }
};
