import { createGlobalStyle, css } from 'styled-components';
import { tokens as tableTokens } from '../Table/tokens';
import { theme } from './theme';
export const GlobalStyle = createGlobalStyle `
  * {
    box-sizing: border-box;
    font-feature-settings: 'salt' on;
  }
  
  pre {
    margin: 0;
  }

  input::placeholder, textarea::placeholder {
    color: ${theme.colors.grey500};
    opacity: .5;
  }
`;
export const globalScrollBarCss = css `
  ::-moz-scrollbar-thumb {
    scrollbar-color: ${theme.colors.grey200} !important;
    scrollbar-width: thin !important;
  }
  ::-webkit-scrollbar {
    width: ${theme.spaces.x8};
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar:horizontal {
    background: ${theme.colors.white};
  }
  ::-webkit-scrollbar:horizontal {
    height: ${theme.spaces.x8};
  }
  ::-webkit-scrollbar-thumb:horizontal,
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.grey200};
    border-radius: ${theme.radius.m};
    &:hover {
      background: ${theme.colors.grey300};
      scrollbar-color: ${theme.colors.grey300} !important;
    }
  }
`;
export const TypographyGlobalStyle = createGlobalStyle `
  html,
  body {
    color: ${theme.colors.grey900};
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.fontSizes.x14};
    font-weight: normal;
    line-height: ${theme.lineHeight.x24};
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
  }
  ${globalScrollBarCss}
  [hidden] {
      display: none!important;
  }
  
  label{
    font-size: inherit;
    line-height: inherit;
  }
  
  blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre{
    margin: 0;
  }
  
  input::placeholder, textarea::placeholder {
    color: ${theme.colors.grey500};
    opacity: .5;
  }

  *, :after, :before {
      box-sizing: border-box;
  }
  a {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    color: ${theme.colors.primary600};
    text-decoration: none;
  }
  img[src='""'] {
    display: none;
  }
  img[src='--'] {
    display: none;
  }
  &&.ui-typography {
      font-family: ${theme.fontFamily.text};
      color: ${theme.colors.grey900};

      h1, h2, h3, h4, h5, h6 {
        color: ${theme.colors.grey700};
        font-weight: ${theme.fontWeight.semiBold};
      }

      h1 {
        font-size: ${theme.fontSizes.x20};
        margin-bottom: ${theme.spaces.x20};
      }

      h2 {
        font-size: ${theme.fontSizes.x18};
        margin-bottom: ${theme.spaces.x16};
      }

      h3 {
        font-size: ${theme.fontSizes.x16};
        margin-bottom: ${theme.spaces.x12};
      }

      p {
        margin-bottom: ${theme.spaces.x12};
        &:last-child {
          margin-bottom: 0;
        }
      }
      hr {
        margin: ${theme.spaces.x24} 0;
      }
      a {
        outline: none;
        text-decoration: none;
        color: ${theme.colors.primary600};
      }
      strong {
        font-weight: ${theme.fontWeight.semiBold};
      }
      em {
        background: ${theme.colors.warning500};
        padding: ${theme.spaces.x4};
        margin: 0 ${theme.spaces.x4};
      }
      blockquote {
        padding: 0 0 0 ${theme.spaces.x8};
        border-left: 4px solid ${theme.colors.grey300};
        opacity: 0.85;
      }

      ul, ol {
        padding-left: ${theme.spaces.x12};
        margin: 0 0 ${theme.spaces.x12};
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul li,
      ol li {
        margin: 0 0 0 ${theme.spaces.x12};
        padding: 0 0 0 ${theme.spaces.x6};
      }

      code {
        margin: 0 ${theme.spaces.x6};
        padding: ${theme.spaces.x4} ${theme.spaces.x6};
        background: ${theme.colors.grey200};
        border: 1px solid ${theme.colors.grey400};
        border-radius: ${theme.radius.s};
      }

      pre {
        padding: ${theme.spaces.x8} ${theme.spaces.x12};
        white-space: pre-wrap;
        word-wrap: break-word;
        background: ${theme.colors.grey100};
        border: 1px solid ${theme.colors.grey200};
        border-radius: ${theme.radius.s};
        code {
          display: inline;
          margin: 0;
          padding: 0;
          font-size: inherit;
          font-family: inherit;
          background: transparent;
          border: 0;
        }
      }

      table {
        border: 1px solid ${tableTokens.Table.borderColor};
        border-radius: ${tableTokens.Table.borderRadius};
        margin-bottom: ${theme.spaces.x12};
        border-spacing: 0;

        th {
          font-weight: ${theme.fontWeight.semiBold};
          background-color: ${tableTokens.Head.backgroundColor};

          &:first-child {
            border-top-left-radius: ${tableTokens.Table.borderRadius};
          }

          &:last-child {
            border-top-right-radius: ${tableTokens.Table.borderRadius};
          }
        }

        th, td {
          padding: 0 ${tableTokens.Cell.padding};
          text-align: left;
          border-bottom: 1px solid ${tableTokens.Table.borderColor};
          height: ${tableTokens.Row.height};
        }
        
        tr:last-child {
          td {
            border-bottom: none;
          }
        }

        tbody tr:hover {
          background-color: ${tableTokens.Row.hover.backgroundColor};
        }
      }
    }
`;
