import { createElement as _createElement } from "react";
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Editor, { useMonaco } from '@monaco-editor/react';
import { newLineToBr } from '@prophecy/utils/react/nodes';
import { noop } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { LANGUAGE_ICON_MAP } from '../../../ExpressionBox/base/components';
import { EXPRESSION_BOX_VALUE_ATTR } from '../../../ExpressionBox/base/constants';
import { StyledLabel } from '../../../Input/styled';
import { tokens as inputTokens } from '../../../Input/tokens';
import { Stack, StackItem } from '../../../Layout';
import { Separator } from '../../../Separator';
import { theme } from '../../../theme';
import { renderWithTooltip } from '../../../Tooltip';
import { Text } from '../../../Typography';
import { LabelWithHint } from '../../../Typography/LabelWithHint';
import { useCursorToolbar } from '../../CursorToolbar';
import { useDecorations, useDefinitions, useDiagnostics, useEditorSave, useGitCodeLens, useSuggestions, useValue } from '../../editorHooks';
import { tagDecorationStyles, useTagDecorations } from '../../editorTags';
import { ConflictCSS, editorTooltipStyle, SliderCSS } from '../../styled';
import { defaultSliderSize, LINE_HEIGHT } from '../../tokens';
import { diagnosticsToDecorations, remeasureFontOnLoad } from '../../utils';
import { BlinkCursorActions, blinkCursorActions } from './blink-cursor';
import { useEditorInsertText } from './hooks';
const EditorContainer = styled(Stack) `
  position: relative;
  ${({ bordered, showFooter, showHead, showTail }) => bordered
    ? `
    border: 1px solid ${theme.colors.grey300};
    border-radius: ${theme.radius.m};
    padding-top: ${showHead ? '0px' : theme.spaces.x4};
    padding-bottom: ${showFooter || showTail ? '0px' : theme.spaces.x4};
  `
    : ''}

  .decoration-highlight {
    background: yellow;
  }

  ${SliderCSS}

  ${ConflictCSS}

  /**
  * The content widget is causing additional spacing for the editor component on bottom.
  * So moving it to top.
  */
  .overflowingContentWidgets {
    position: absolute;
    top: 0;
  }

  .decorationsOverviewRuler {
    background: ${theme.colors.white};
  }

  && {
    .editor-widget.suggest-widget {
      .tree {
        margin: 0;
      }
    }
  }

  .diagnostic-decoration-error,
  .diagnostic-decoration-warning {
    display: flex;
    align-items: center;
    padding-left: ${theme.spaces.x8};
    &::after {
      content: '';
      width: ${theme.sizes.x8};
      height: ${theme.sizes.x8};
      border-radius: 50%;
    }
  }

  .diagnostic-decoration-error {
    &::after {
      background-color: ${theme.colors.error500};
    }
  }

  .diagnostic-decoration-warning {
    &::after {
      background-color: ${theme.colors.warning500};
    }
  }
  .monaco-toolbar {
    display: none;
  }

  .monaco-editor-overlaymessage {
    min-width: 210px;
  }

  ${tagDecorationStyles}

  ${editorTooltipStyle}
`;
const EditorFooter = styled(Stack) `
  background: ${theme.colors.grey50};
  padding: 0 ${theme.spaces.x8};
  ${({ bordered }) => bordered
    ? `
    border-radius: 0 0 ${theme.radius.m} ${theme.radius.m};
    border-top: 1px solid ${theme.colors.grey300};
  `
    : ''}
`;
export const EditorTools = styled(Stack) `
  position: absolute;
  right: ${theme.spaces.x20};
  top: ${theme.spaces.x6};
  ${({ withHead }) => withHead
    ? `
    top: ${theme.spaces.x40};
  `
    : ''}
`;
const headTailStyles = css `
  background: ${theme.colors.grey50};
  padding: 0 ${theme.spaces.x12};
  height: ${theme.sizes.x36};
  font-size: ${theme.fontSizes.x12};
  color: ${theme.colors.grey700};
`;
export const EditorHead = styled(Stack) `
  ${headTailStyles};
  ${({ bordered }) => bordered
    ? `
    border-bottom: 1px solid ${theme.colors.grey300};
    border-radius: ${theme.radius.m} ${theme.radius.m} 0 0;
  `
    : ''}
`;
export const EditorTail = styled(Stack) `
  ${headTailStyles};
  ${({ bordered }) => bordered
    ? `
    border-top: 1px solid ${theme.colors.grey300};
    border-radius: 0 0 ${theme.radius.m} ${theme.radius.m};
  `
    : ''}
`;
const StyledPlaceholder = styled.pre `
  color: black;
  font-size: ${theme.fontSizes.x12};
  width: calc(100% - ${(props) => props.left}px - ${theme.spaces.x32});
  top: 0;
  left: ${(props) => props.left}px;
  right: 0;
  opacity: 0.5;
  position: absolute;
  user-select: none;
  line-height: ${LINE_HEIGHT}px;
  font-family: ${theme.fontFamily.code};
`;
function LineAndColumn({ editor, editorInFocus }) {
    const [position, setPosition] = useState();
    useEffect(() => {
        if (!editor)
            return;
        editor.onDidChangeCursorPosition((e) => {
            const selection = editor.getSelection();
            if (!selection) {
                setPosition(undefined);
                return null;
            }
            setPosition({
                line: selection.startLineNumber,
                column: selection.startColumn
            });
        });
    }, [editor]);
    if (!editor || !position || !editorInFocus)
        return null;
    return (_jsxs(Text, { level: 'xs', children: ["Ln ", position.line, ", Col ", position.column] }));
}
export const defaultOptions = {
    lineNumbersMinChars: 7,
    fontFamily: theme.fontFamily.code,
    minimap: { enabled: false },
    renderValidationDecorations: 'on',
    lineHeight: LINE_HEIGHT
};
export default function BaseEditor({ readOnly = false, language, path, delay = 250, onEditorLoad, onEditorUnload, decorations = [], definitions, diagnostics = [], value: _value = '', monacoEditorProps, onChange = noop, onBlur, onSave, autoSaveAfterDelay, placeholder, suggestions, getInlineSuggestion, height, hint, tooltip, ariaLabel, className, showLanguageHint = false, label, prepareSuggestions, editorActions, renderEditor = (node) => node, showFooter, bordered, tags, head, tail, renderCursorToolbar }) {
    const [editor, setEditor] = useState();
    const [editorInFocus, setFocusState] = useState(false);
    const monaco = useMonaco();
    useEditorInsertText(editor, monaco);
    const containerRef = useRef(null);
    const { value: stateValue, handleChange: _handleChange, handleBlur } = useValue({
        getValue: () => removeDecoration(editor === null || editor === void 0 ? void 0 : editor.getValue()),
        value: _value,
        onChange,
        onBlur,
        delay,
        containerRef
    });
    // handle tag decoration
    const [value, handleChange, removeDecoration, hasTags] = useTagDecorations({
        editor,
        monaco,
        tags,
        value: stateValue,
        onChange: _handleChange,
        readOnly
    });
    /** Handle editor mount and unmount */
    const onMount = (editor, monaco) => {
        setEditor(editor);
        remeasureFontOnLoad(monaco, options.fontSize);
        onEditorLoad === null || onEditorLoad === void 0 ? void 0 : onEditorLoad(editor, monaco);
        blinkCursorActions(editor);
        editor.onDidBlurEditorWidget(() => {
            setFocusState(false);
            handleBlur();
        });
        editor.onDidFocusEditorWidget(() => {
            setFocusState(true);
            editor.trigger('editor', BlinkCursorActions.End, null);
        });
    };
    useEffect(() => {
        return () => {
            onEditorUnload === null || onEditorUnload === void 0 ? void 0 : onEditorUnload(editor);
        };
    }, [onEditorUnload, editor]);
    // handle suggestions
    useSuggestions(suggestions, getInlineSuggestion, editor, monaco, prepareSuggestions);
    // handle decorations
    useDecorations([...decorations, ...diagnosticsToDecorations(diagnostics)], editor, monaco);
    // show glyph margin
    const glyphMargin = decorations &&
        decorations.some((decoration) => { var _a; return decoration.type === 'lineIcon' || ((_a = decoration.options) === null || _a === void 0 ? void 0 : _a.glyphMarginClassName); });
    // handle declarations
    useDefinitions(language, definitions, editor, monaco);
    // handle diagnostics
    useDiagnostics(diagnostics, editor, monaco);
    // handle git codelens
    useGitCodeLens(language, monaco, editor);
    // handle editor save
    useEditorSave({ value, onChange, onSave, editor, editorInFocus, monaco, autoSaveAfterDelay });
    useCursorToolbar({ editor, renderCursorToolbar });
    const options = (monacoEditorProps === null || monacoEditorProps === void 0 ? void 0 : monacoEditorProps.options) || {};
    const tabSize = options.tabSize || 4;
    /**
     * add path as key for write mode, to avoid code history related bugs (CMD + Z)
     * we don't need it for readonly as people can't edit there so no such issues will arise
     * Note: Key change will unmount and mount component again.
     */
    const key = !readOnly ? path : undefined;
    // show placeholder when editor is loaded, not in focus and doesn't have value but have a placeholder
    const showPlaceholder = Boolean(editor && !value && !editorInFocus && placeholder);
    const handlePlaceholderClick = () => {
        editor === null || editor === void 0 ? void 0 : editor.focus();
        setFocusState(true);
    };
    const getPlaceholderPosition = () => {
        var _a, _b;
        /**
         * Monaco editable area start after the line number column (named as margin),
         * Placeholder needs to be placed after the line numbers. so we find the left
         * position based on line number area (.margin) width
         * */
        return ((_b = (_a = editor === null || editor === void 0 ? void 0 : editor.getDomNode()) === null || _a === void 0 ? void 0 : _a.querySelector('.margin')) === null || _b === void 0 ? void 0 : _b.offsetWidth) || 70; // margin width;
    };
    const LanguageIcon = LANGUAGE_ICON_MAP[language];
    let labelUI = label ? _jsx(StyledLabel, { children: label }) : null;
    if (hint && label) {
        labelUI = _jsx(LabelWithHint, { hint: hint, children: labelUI });
    }
    const unicodeHighlightOptions = hasTags
        ? {
            unicodeHighlight: {
                allowedCharacters: {
                    '\u200B': true
                }
            }
        }
        : {};
    let editorBox = renderEditor(_createElement(Editor, Object.assign({}, monacoEditorProps, { height: '100%', options: Object.assign(Object.assign(Object.assign(Object.assign({}, defaultOptions), options), { tabSize,
            readOnly,
            glyphMargin, scrollbar: Object.assign({ alwaysConsumeMouseWheel: false, horizontalSliderSize: defaultSliderSize, verticalScrollbarSize: defaultSliderSize }, options.scrollbar), stickyScroll: Object.assign({ enabled: false }, (options.stickyScroll || {})) }), unicodeHighlightOptions), onMount: onMount, language: language, value: value, path: path, onChange: handleChange, key: key })));
    if (tooltip) {
        editorBox = (_jsx(_Fragment, { children: renderWithTooltip(_jsx(Stack, { width: '100%', height: '100%', children: editorBox }), tooltip) }));
    }
    return (_jsxs(EditorContainer, { 'data-component': 'Editor',
        [EXPRESSION_BOX_VALUE_ATTR]: value, gap: inputTokens.Root.gap, height: height || '100%', width: '100%', bordered: bordered, showFooter: showFooter, showHead: Boolean(head), showTail: Boolean(tail), "aria-label": ariaLabel, className: className, ref: containerRef, children: [labelUI, head && (_jsx(EditorHead, { bordered: bordered, align: 'center', children: head })), _jsxs(StackItem, { grow: '1', children: [editorBox, _jsxs(EditorTools, { direction: 'horizontal', gap: theme.spaces.x6, withHead: Boolean(head), alignY: 'center', children: [showLanguageHint && LanguageIcon && _jsx(LanguageIcon, { className: 'expression-language-icon' }), editorActions] }), showPlaceholder && (_jsx(StyledPlaceholder, { left: getPlaceholderPosition(), onClick: handlePlaceholderClick, children: newLineToBr(placeholder) }))] }), tail && (_jsx(EditorTail, { bordered: bordered, align: 'center', children: tail })), showFooter && (_jsx(StackItem, { shrink: '0', children: _jsxs(EditorFooter, { height: theme.spaces.x24, direction: 'horizontal', gap: theme.spaces.x12, alignY: 'center', bordered: bordered, align: 'space-between', children: [_jsxs(Stack, { height: '100%', direction: 'horizontal', alignY: 'center', padding: `${theme.spaces.x2} 0`, gap: theme.spaces.x8, children: [_jsxs(Text, { level: 'xs', children: ["Spaces: ", tabSize] }), editorInFocus && _jsx(Separator, { orientation: 'vertical' }), _jsx(LineAndColumn, { editorInFocus: editorInFocus, editor: editor })] }), LanguageIcon && _jsx(LanguageIcon, { height: theme.spaces.x20 })] }) }))] }));
}
