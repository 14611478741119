import { Callable } from '@prophecy/interfaces/generic';
import { ErrorTrace, message } from '@prophecy/ui';
import { usePersistentCallback, useThrottle } from '@prophecy/utils/react/hooks';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import type { BaseProcess, BaseProcessMetadata, BaseState, Connection, GenericGraph, Metadata } from '../common/types';
import { CommonActionTypes } from '../common/types';
import { useReadOnlyFlag } from '../common/useReadOnlyFlag';
import { useHistory } from '../HistoryManager/context';
import { CompilationStatus, WorkflowState } from '../redux/types';
import { toggleLoader } from './base/methods';
import { LSP } from './base/types';
import { LSPWebSocketClient } from './websocket/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OverrideLSPMethod = createContext<any>(undefined);

export type LspMethodsOptions = {
  readonly?: boolean;
  onError?: (error: LSP.Error) => void;
  onSuccess?: (data: LSP.Success) => void;
  showGlobalLoader?: boolean;
  skipStateUpdate?: boolean;
};

function onError(err: LSP.Error) {
  message.error({
    content: err.message,
    detail: err.data ? <ErrorTrace>{err.data}</ErrorTrace> : null
  });
}

function onSuccess(resp: LSP.Success) {
  const content = resp?.message;
  if (content) {
    message.success({
      content
    });
  }
}

const defaultOptions = { onError, showGlobalLoader: false, onSuccess };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractLSPCallback<M extends object, T extends keyof M> = M[T] extends (...arg: any) => infer R ? R : never;

export function createLSPMethodHook<
  C extends LSPWebSocketClient<string, string> | undefined,
  M extends object,
  MD extends Partial<{ [K in keyof M]: LspMethodsOptions }>
>(methods: M, methodDefaults: MD, getClient: () => C) {
  return function useLSPMethod<T extends keyof M>(type: T, options?: LspMethodsOptions) {
    const historyContext = useHistory();
    const _options = { ...defaultOptions, ...methodDefaults[type], ...options };
    const store =
      useStore<
        BaseState<GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>, Metadata>
      >();

    const lspClient = getClient();

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<LSP.Error>();
    const [data, setData] = useState<LSP.Success>();
    const readOnly = useReadOnlyFlag();

    const method = usePersistentCallback(async function dispatch(...args: LSP.ExtractParams<ExtractLSPCallback<M, T>>) {
      const [payload, ...rest] = args;
      // @ts-ignore
      const { historical, expectation } = store.getState();

      const _readOnly = options?.readonly ?? readOnly;
      const isDatasetInfo = Boolean(expectation?.isDatasetInfo);
      // allow code view on readonly mode. For historical mode things are preloaded, so need to send the event
      if (_readOnly && (historical || type !== LSP.Method.editorLoadView) && !isDatasetInfo) {
        return {} as LSP.CallbackReturnType;
      }

      if (isDatasetInfo) {
        if (!_options.skipStateUpdate) store.dispatch({ type: type.toString(), payload });
        return { error, data, isLoading, isError: !!error, type };
      }
      let closeSubscription: Callable | undefined;
      try {
        setLoading(true);
        if (_options.showGlobalLoader) toggleLoader(store)(true);

        const _method = methods[type] as unknown as Callable;
        closeSubscription = lspClient?.listen(LSP.Notification.close, () => {
          setLoading(false);
          if (_options.showGlobalLoader) toggleLoader(store)(false);
        });
        const lspPromise = _method(lspClient, store, historyContext)(payload, ...rest);
        /**
         * store on dispatch after calling lsp method. Store dispatch can synchronously trigger another didChange,
         * which can break the order od didChange happening on UI vs didChange going on backend.
         */
        if (!_options.skipStateUpdate) {
          store.dispatch({ type: type.toString(), payload });
        }
        const data = (await lspPromise) as LSP.ExtractReturnType<ExtractLSPCallback<M, T>>;
        setData(data);
        setError(undefined);
        _options.onSuccess?.(data);
        return { data } as LSP.CallbackReturnType;
      } catch (err) {
        const _err = err as LSP.Error;
        setData(undefined);
        setError(_err);
        _options.onError?.(_err);
        throw _err;
      } finally {
        setLoading(false);
        if (_options.showGlobalLoader) toggleLoader(store)(false);
        closeSubscription?.();
      }
    });
    const returnValue = { method, error, data, isLoading, isError: !!error, type };
    type R = typeof returnValue;
    const parentHook = useContext<R>(OverrideLSPMethod);
    // temp solution, currently it allows to override only 1 type, change it later to allow override for multiple types
    if (parentHook && parentHook.type === type) {
      // locking type with context
      return parentHook;
    }
    return returnValue;
  };
}

export function useComponentCompilationStatus(processId: string) {
  return useSelector<WorkflowState, CompilationStatus>((state) => state.compilationStatus[processId]);
}

export function useCreateLSPClient<T extends Callable>(
  createLSPClient: T
): {
  lspClient: ReturnType<T>;
  reconnectLSPClient: () => void;
} {
  // NOTE: keeping it as separate memo as useState callback gets called again in case the component is hot refreshed.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialLspClient = useMemo(() => createLSPClient(), []);

  const [lspClient, setLspClient] = useState(initialLspClient);

  const reconnectLSPClient = usePersistentCallback(() => {
    setLspClient(createLSPClient());
  });

  // keep the reconnect method with the lspClient
  lspClient.reconnect = reconnectLSPClient;

  return { lspClient, reconnectLSPClient };
}

export function useHandleLogMessageNotification(dispatch: Dispatch) {
  const throttledDispatch = useThrottle(
    ({ params }: { params: { message: string } }) => {
      dispatch({
        type: CommonActionTypes.logMessage,
        payload: params.message
      });
    },
    1000,
    { trailing: true, leading: true }
  );

  return useCallback(
    ({ params }: { params: { message: string } }) => {
      // at the end of log message don't wait just dispatch the message
      if (params.message === '') {
        throttledDispatch.cancel();
        dispatch({
          type: CommonActionTypes.logMessage,
          payload: params.message
        });
      } else {
        throttledDispatch({ params });
      }
    },
    [dispatch, throttledDispatch]
  );
}
