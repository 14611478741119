import { useLazyEffect, useOnMount, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { parse } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
export function useDatePopupInterraction(toggleIconRef, containerRef, togglePopover, reset, resetDateToNull) {
    const isCancelButtonActive = () => { var _a, _b, _c; return ((_c = (_b = (_a = toggleIconRef.current) === null || _a === void 0 ? void 0 : _a.children[0]) === null || _b === void 0 ? void 0 : _b.attributes.getNamedItem('data-action')) === null || _c === void 0 ? void 0 : _c.value) === 'cancel'; };
    useOnMount(function () {
        const container = containerRef.current;
        function handle(e) {
            var _a;
            const target = e.target;
            const clickedCalendarIcon = (_a = toggleIconRef.current) === null || _a === void 0 ? void 0 : _a.contains(target);
            if (clickedCalendarIcon) {
                if (isCancelButtonActive()) {
                    resetDateToNull();
                }
                else {
                    return togglePopover((prevState) => !prevState);
                }
            }
        }
        container.addEventListener('click', handle);
        return function () {
            container.removeEventListener('click', handle);
        };
    });
    const handlePopupOutsideInterraction = function (e) {
        var _a, _b;
        const target = e.target;
        const clickedCalendarIcon = (_a = toggleIconRef.current) === null || _a === void 0 ? void 0 : _a.contains(target);
        // when isCancelButtonActive is true, handlePopupOutsideInterraction is getting called twice
        // By the Popover component and target is first sent as calendar icon in first call
        // and the parent container in second call which makes clickedCalendarIcon as false
        // Ref: pointerDownOutside and focusOutside is calling onInteractOutside fn in
        // https://github.com/radix-ui/primitives/blob/b9bba52d4c040d7c37b83b85e60c65b843876877/packages/react/dismissable-layer/src/DismissableLayer.tsx
        if (clickedCalendarIcon || isCancelButtonActive()) {
            // handled in listener attached to container in useEffect
            return;
        }
        const clickedAnywhereInRangeInputContainer = (_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.contains(target);
        if (!clickedAnywhereInRangeInputContainer) {
            togglePopover(false);
            reset();
        }
    };
    return { handlePopupOutsideInterraction };
}
export function useInternalValue({ value, defaultValue, onChange, autoSync }) {
    const [internalState, setInternalState] = useState(value !== null && value !== void 0 ? value : defaultValue);
    const syncValue = usePersistentCallback((val) => {
        if (autoSync && onChange) {
            onChange(val);
        }
    });
    useLazyEffect(() => {
        syncValue(internalState);
    }, [autoSync]);
    useEffect(() => {
        setInternalState(value);
    }, [value]);
    const handleChange = usePersistentCallback((val) => {
        setInternalState(val);
        syncValue(val);
    });
    const sync = usePersistentCallback(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange(internalState);
    });
    const reset = usePersistentCallback(() => {
        setInternalState(value);
    });
    return { value: internalState, onChange: handleChange, sync, reset };
}
export function usePickerInput({ format, onChange, isValid, sync, reset }) {
    const [popoverOpen, togglePopoverOpen] = useState(false);
    const containerRef = useRef(null);
    const iconRef = useRef(null);
    const handleInputChange = usePersistentCallback((val) => {
        const isValidInput = isValid(val, format);
        if (isValidInput) {
            const parsedDate = parse(val, format, new Date());
            onChange === null || onChange === void 0 ? void 0 : onChange(parsedDate);
        }
    });
    const handleSelect = usePersistentCallback((date) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(date);
    });
    const resetDateToNull = usePersistentCallback(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange(null);
    });
    const { handlePopupOutsideInterraction } = useDatePopupInterraction(iconRef, containerRef, togglePopoverOpen, reset, resetDateToNull);
    const onApply = usePersistentCallback(() => {
        togglePopoverOpen(false);
        sync();
    });
    const onCancel = usePersistentCallback(() => {
        togglePopover(false);
        reset();
    });
    const togglePopover = usePersistentCallback((value) => {
        togglePopoverOpen(value);
        if (value === false || (typeof value === 'function' && value(popoverOpen) === false)) {
            reset();
        }
    });
    return {
        popoverOpen,
        togglePopoverOpen,
        containerRef,
        iconRef,
        handleInputChange,
        handleSelect,
        onApply,
        onCancel,
        handlePopupOutsideInterraction
    };
}
