import { useForceBlurOnUnmount } from '@prophecy/utils/react/focus';
import { useDebounce } from '@prophecy/utils/react/hooks';
import { unescapeSequenceChar, escapeSequenceChar } from '@prophecy/utils/string';
import { noop } from 'lodash-es';
import { useRef, useState, useEffect } from 'react';
export var TriggerKind;
(function (TriggerKind) {
    TriggerKind["User"] = "user";
    TriggerKind["Auto"] = "auto";
})(TriggerKind || (TriggerKind = {}));
export function useInput({ value, onClear, onChange = noop, delay, allowEscapeSequence, onBlur, onPressEnter, onKeyPress, preFormat, ref, allowClear }) {
    const [state, setState] = useState(value);
    useEffect(() => {
        setState(value);
    }, [value]);
    const slowOnChange = useDebounce(onChange, delay);
    function forceOnChange(event) {
        // cancel debouce
        slowOnChange.cancel();
        let { value: _value } = event.target;
        // unescape the input value if allowEscapeSequence is true
        _value = allowEscapeSequence ? unescapeSequenceChar(_value) : _value;
        if (_value !== value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(_value, TriggerKind.User);
        }
    }
    // On blur or on enter don't wait for debouce and update the parent with new value
    const handleBlur = (e) => {
        if (delay)
            forceOnChange(e);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            forceOnChange(e);
            onPressEnter === null || onPressEnter === void 0 ? void 0 : onPressEnter(e);
        }
        onKeyPress === null || onKeyPress === void 0 ? void 0 : onKeyPress(e);
    };
    // id delay is set to 0, directly call onChange method, to avoid onChange getting called on different event cycle
    const _onChange = delay === 0 ? onChange : slowOnChange;
    const handleChange = (e, triggerKind) => {
        var _a;
        let { value: _value } = e.target;
        _value = preFormat ? (_a = preFormat(_value)) === null || _a === void 0 ? void 0 : _a.toString() : _value;
        setState(_value);
        _onChange(_value, triggerKind);
    };
    const handleClear = () => {
        onClear === null || onClear === void 0 ? void 0 : onClear();
        setState('');
        _onChange('', TriggerKind.User);
    };
    // handle input focus on click of container
    const inputRef = useRef(null);
    const setFocus = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const setRef = (elm) => {
        // set forwarded ref
        if (typeof ref === 'function') {
            ref(elm);
        }
        else if (ref && 'current' in ref) {
            ref.current = elm;
        }
        inputRef.current = elm;
    };
    useForceBlurOnUnmount(inputRef, handleBlur);
    const _value = allowEscapeSequence && typeof state === 'string' ? escapeSequenceChar(state) : state;
    const valueLength = (_value || '').toString().length;
    const showClear = allowClear && valueLength > 0;
    return {
        showClear,
        setFocus,
        _value,
        setRef,
        handleChange,
        handleBlur,
        handleKeyPress,
        handleClear,
        valueLength
    };
}
