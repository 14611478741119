import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { isAbsoluteURL, LinkTarget } from '@prophecy/utils/url';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
const linkTarget = ({ properties }) => {
    return isAbsoluteURL(properties.href) ? LinkTarget._blank : LinkTarget._self;
};
export default function Markdown(_a) {
    var { children: markdownContent, className, remarkPlugins } = _a, restProps = __rest(_a, ["children", "className", "remarkPlugins"]);
    const remarkPluginsList = remarkPlugins || [];
    return (_jsx(ReactMarkdown, Object.assign({}, restProps, { className: `ui-typography ${className || ''}`, rehypePlugins: [[rehypeExternalLinks, { target: linkTarget, rel: ['nofollow', 'noopener'] }]], remarkPlugins: [remarkGfm, ...remarkPluginsList], children: markdownContent === null || markdownContent === void 0 ? void 0 : markdownContent.replace(/\n/g, '  \n') })));
}
