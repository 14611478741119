import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { isSameDay, isWithinInterval } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { OptimisticRange, RangeEndPoint, StyledDateCell } from '../styled';
import { CELL_IN_RANGE_CLASS, CELL_IN_VIEW_CLASS, CELL_RANGE_END_CLASS, CELL_RANGE_START_CLASS } from '../tokens';
import { isFirstDayOfMonth, isLastDayOfMonth } from '../utils/utils';
const formatter = new Intl.DateTimeFormat('en-GB'); // 'en-GB' is for UK date format (dd/mm/yyyy)
export const DateCellRenderer = function ({ date, selectedDate, events, isRangePicker, selectedRange = [] }) {
    var _a, _b;
    const isToday = isSameDay(date, new Date());
    const cellRef = useRef(null);
    const [isCellInView, setIsCellInView] = useState(false);
    const [isSlectedRange, setIsSelectedRange] = useState(false);
    const [isOptimisticSelectedRange, setIsOptimisticSelectedRange] = useState(false);
    const formattedDate = formatter.format(date);
    const calEvents = events === null || events === void 0 ? void 0 : events[formattedDate];
    const [hasNextElementInRange, setHasNextElementInRange] = useState(false);
    const isSunday = date.getDay() === 0;
    const isSaturday = date.getDay() === 6;
    const selectedRangeValid = (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange[0]) && (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange[1]);
    function updateSelectedRange(val) {
        if (selectedRangeValid) {
            setIsOptimisticSelectedRange(val);
        }
        else {
            setIsSelectedRange(val);
        }
    }
    useEffect(function () {
        var _a, _b, _c;
        const el = (_a = cellRef === null || cellRef === void 0 ? void 0 : cellRef.current) === null || _a === void 0 ? void 0 : _a.parentElement;
        setIsCellInView(!!(el === null || el === void 0 ? void 0 : el.classList.contains(CELL_IN_VIEW_CLASS)));
        setHasNextElementInRange(false);
        if (selectedRangeValid) {
            setHasNextElementInRange(true); // because range is valid
            if (isSameDay(date, selectedRange[0])) {
                setIsSelectedRange('start');
            }
            else if (isSameDay(date, selectedRange[1])) {
                setIsSelectedRange('end');
            }
            else if (isWithinInterval(date, { start: selectedRange[0], end: selectedRange[1] })) {
                setIsSelectedRange('middle');
            }
            else {
                setIsSelectedRange(false);
            }
        }
        if (el === null || el === void 0 ? void 0 : el.classList.contains(CELL_RANGE_START_CLASS)) {
            const hasNextElementInRange = (((_b = el === null || el === void 0 ? void 0 : el.nextElementSibling) === null || _b === void 0 ? void 0 : _b.classList.contains(CELL_IN_RANGE_CLASS)) ||
                ((_c = el === null || el === void 0 ? void 0 : el.nextElementSibling) === null || _c === void 0 ? void 0 : _c.classList.contains(CELL_RANGE_END_CLASS)));
            setHasNextElementInRange(hasNextElementInRange);
            updateSelectedRange('start');
        }
        else if (el === null || el === void 0 ? void 0 : el.classList.contains(CELL_IN_RANGE_CLASS)) {
            updateSelectedRange('middle');
        }
        else if (el === null || el === void 0 ? void 0 : el.classList.contains(CELL_RANGE_END_CLASS)) {
            updateSelectedRange('end');
        }
        else {
            updateSelectedRange(false);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, selectedRange, selectedRangeValid]);
    const showRangeSelection = isRangePicker &&
        (isSlectedRange === 'middle' || // is in middle of range
            isSlectedRange === 'end' || // is end of range and not sunday
            (isSlectedRange === 'start' && hasNextElementInRange)); // is start and has next element in range
    const showOptimisticRangeSelection = isRangePicker && isCellInView && ['start', 'middle', 'end'].includes(isOptimisticSelectedRange);
    return (_jsxs(_Fragment, { children: [showRangeSelection ? (_jsx(RangeEndPoint, { selectedRangeValid: !!selectedRangeValid, isStartOfRange: isSameDay(date, selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange[0]), isEndOfRange: isSameDay(date, selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange[1]), isSelectedRange: isSlectedRange, isCellInView: isCellInView, isFirstDayOfMonth: isFirstDayOfMonth(date), isLastDayOfMonth: isLastDayOfMonth(date), isSunday: isSunday, isSaturday: isSaturday })) : null, _jsx(StyledDateCell, { selected: selectedDate ? isSameDay(selectedDate, date) : false, ref: cellRef, isCellInView: isCellInView, isToday: isToday, hasEvents: !!calEvents, isRangePicker: !!isRangePicker, selectedRangeValid: !!selectedRangeValid, children: (_b = (_a = date).getDate) === null || _b === void 0 ? void 0 : _b.call(_a) }), showOptimisticRangeSelection ? (_jsx(OptimisticRange, { isStartOfRange: isSameDay(date, selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange[0]), isEndOfRange: isSameDay(date, selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange[1]), isSelectedRange: isOptimisticSelectedRange, isCellInView: isCellInView, isFirstDayOfMonth: isFirstDayOfMonth(date), isLastDayOfMonth: isLastDayOfMonth(date), isSunday: isSunday, isSaturday: isSaturday })) : null] }));
};
