import { noop } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { createContext } from 'use-context-selector';

import { LocalPreferenceKeys, ProjectPreferenceKeys, SetPreference } from './types';
import { useSetPreferenceHistory, usePreference } from './util';

const LOCAL_PREFERENCE_STORE_KEY = 'prophecy.localPreferences';

export type ProjectPreference = Partial<{
  [ProjectPreferenceKeys.expandedEntities]: string[];
  [key: `${string}_${ProjectPreferenceKeys.expandedEntities}`]: string[];
}>;

type LocalPreferenceValues = Partial<{
  [LocalPreferenceKeys.projectPreferences]: Record<string, ProjectPreference>;
}>;

type LocalPreferenceContextType = {
  preferences: LocalPreferenceValues;
  setPreference: SetPreference<LocalPreferenceValues>;
};

const LocalPreferenceContext = createContext<LocalPreferenceContextType>({
  preferences: {},
  setPreference: noop
});

function useLocalPreferenceStore() {
  const stateString = localStorage.getItem(LOCAL_PREFERENCE_STORE_KEY) || '{}';
  const [localPreferences, setLocalPreferences] = useState<LocalPreferenceValues>(JSON.parse(stateString));

  const persistPreference = (preferences: LocalPreferenceValues) => {
    localStorage.setItem(LOCAL_PREFERENCE_STORE_KEY, JSON.stringify(preferences));
  };

  const setPreference = useSetPreferenceHistory(localPreferences, setLocalPreferences, persistPreference);

  return { localPreferences, setPreference };
}

export function LocalPreferenceProvider({ children }: { children: React.ReactNode }) {
  const { localPreferences, setPreference } = useLocalPreferenceStore();
  const memoizedContextValue = useMemo(() => {
    return { preferences: localPreferences, setPreference };
  }, [localPreferences, setPreference]);
  return <LocalPreferenceContext.Provider value={memoizedContextValue}>{children}</LocalPreferenceContext.Provider>;
}

export function useLocalPreference<T extends keyof LocalPreferenceValues>(key: T) {
  return usePreference(LocalPreferenceContext, key);
}
