import { jsx as _jsx } from "react/jsx-runtime";
import { DateCellRenderer } from './DateCellRenderer';
import { MonthCellRenderer } from './MonthCellRenderer';
import { TimeCellRenderer } from './TimeCellRenderer';
import { YearCellRenderer } from './YearCellRenderer';
export const CellRenderer = function (props) {
    switch (props.info.type) {
        case 'date':
            return _jsx(DateCellRenderer, Object.assign({}, props));
        case 'time':
            return _jsx(TimeCellRenderer, Object.assign({}, props));
        case 'month':
            return _jsx(MonthCellRenderer, Object.assign({}, props));
        case 'year':
            return _jsx(YearCellRenderer, Object.assign({}, props));
        default:
            return null;
    }
};
