import { useAfterNextRender, useOnce } from '@prophecy/utils/react/hooks';
import { useEffect } from 'react';
// Used for scenarios where either all fields should be filled or all fields should be empty
export const useAllOrNone = (formContext, { keys }) => {
    const keySet = useOnce(() => keys);
    // If all the fields are empty, remove the required rule.
    // If some values are filled then all fields should be filled
    const isRequired = keySet.some((key) => Boolean(formContext.watch(key)));
    const afterNextRender = useAfterNextRender();
    useEffect(() => {
        // when removing required fields trigger the validation rule to clear error messages
        if (!isRequired) {
            afterNextRender(() => {
                keySet.forEach((key) => formContext.trigger(key));
            });
        }
    }, [keySet, isRequired, formContext, afterNextRender]);
    return isRequired;
};
