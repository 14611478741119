import { jsx as _jsx } from "react/jsx-runtime";
import { DateRangeInput } from './inputVariants/DateRangeInput';
import { DefaultDateInput } from './inputVariants/DefaultDateInput';
import { TimeInput } from './inputVariants/TimePickerInput';
import { PICKER_VARIANT } from './types';
export const DatePickerInput = function (props) {
    switch (props.dateVariant) {
        case PICKER_VARIANT.DATE_RANGE: {
            return _jsx(DateRangeInput, Object.assign({}, props));
        }
        case PICKER_VARIANT.TIME: {
            return _jsx(TimeInput, Object.assign({}, props));
        }
        case PICKER_VARIANT.DATE: {
            return _jsx(DefaultDateInput, Object.assign({}, props));
        }
    }
};
