/**
 * Simple implementation of a feature flag system that can be extended to featch values from backend
 * in the future.
 */

import { noop } from 'lodash-es';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import {
  COPILOT_AUDIO_PROMPT_ENABLED,
  filteringOnInterimTableKey,
  SPEC_FLOW_ENABLED,
  WEBSOCKET_COMPRESSION_KEY,
  WORKFLOW_DEBUG_MODE_KEY
} from '../../utils/localstorage-keys';

export type FeatureValue = {
  enabled: boolean;
  localStorageKey: string;
  label: string;
  requiresPageRefresh?: boolean;
};

const INITIAL_FLAGS = {
  disableWebsocketConnection: {
    enabled: false,
    localStorageKey: WEBSOCKET_COMPRESSION_KEY,
    label: 'Disable Websocket Compression',
    requiresPageRefresh: true
  },
  enableCopilotAudioPrompt: {
    enabled: false,
    label: 'Copilot Audio Prompt',
    localStorageKey: COPILOT_AUDIO_PROMPT_ENABLED,
    requiresPageRefresh: true
  },
  enableDebugMode: {
    enabled: false,
    label: 'Debug Mode',
    localStorageKey: WORKFLOW_DEBUG_MODE_KEY
  },
  enableFilteringOnInterimTable: {
    enabled: false,
    label: 'Filtering on interim table',
    localStorageKey: filteringOnInterimTableKey
  },
  specFlow: {
    enabled: false,
    label: 'Spec Flow ',
    localStorageKey: SPEC_FLOW_ENABLED
  }
} as const;

type FeatureFlags = { [key in Feature]?: FeatureValue };

export type Feature = keyof typeof INITIAL_FLAGS;

const LS_KEY_MAPPING: Record<string, Feature> = Object.keys(INITIAL_FLAGS).reduce(
  (acc, flagKey) => {
    const val = INITIAL_FLAGS[flagKey as Feature];
    if (val && val.localStorageKey) {
      acc[val.localStorageKey] = flagKey as Feature;
    }
    return acc;
  },
  {} as Record<string, Feature>
);
const FEATURE_LS_KEY_MAPPING: Record<Feature, string> = Object.keys(INITIAL_FLAGS).reduce(
  (acc, flagKey) => {
    const val = INITIAL_FLAGS[flagKey as Feature];
    if (val) {
      acc[flagKey as Feature] = val.localStorageKey;
    }
    return acc;
  },
  {} as Record<Feature, string>
);

const FeatureFlagsContext = createContext<{
  features: FeatureFlags;
  toggleFeatureValue: (feature: Feature) => void;
  setFeatureValue: (feature: Feature, newVal: boolean) => void;
}>({
  features: INITIAL_FLAGS,
  toggleFeatureValue: noop,
  setFeatureValue: noop
});

export function FeatureFlagsProvider({ children }: PropsWithChildren<{}>) {
  const [flags, setFlags] = useState<FeatureFlags>(() =>
    Object.keys(INITIAL_FLAGS).reduce((acc, featureKey) => {
      const flag = featureKey as Feature;
      const featureValue = INITIAL_FLAGS[flag];
      if (!featureValue) {
        return acc;
      }
      const lsValue = localStorage.getItem(featureValue.localStorageKey) ?? '0';
      acc[featureKey as Feature] = {
        ...featureValue,
        enabled: lsValue === 'true' || lsValue === '1' ? true : featureValue.enabled
      };
      return acc;
    }, {} as FeatureFlags)
  );

  useEffect(() => {
    const handleStorageEvent = (ev: StorageEvent) => {
      const key = ev.key;
      if (!key || !(key in LS_KEY_MAPPING)) {
        return;
      }
      const newVal = ev.newValue;
      setFlags((currentFlags) => {
        const featureFlag = LS_KEY_MAPPING[key];
        const data = currentFlags[featureFlag] as FeatureValue;
        return {
          ...currentFlags,
          [featureFlag]: {
            ...data,
            enabled: newVal === 'true' || newVal === '1'
          }
        };
      });
    };
    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);
  const setFeatureValue = (feature: Feature, value: boolean) => {
    setFlags((currentFlags) => ({
      ...currentFlags,
      [feature]: {
        ...currentFlags[feature],
        enabled: value
      }
    }));
    localStorage.setItem(FEATURE_LS_KEY_MAPPING[feature], value ? 'true' : 'false');
    if ('requiresPageRefresh' in INITIAL_FLAGS[feature]) {
      window.location.reload();
    }
  };
  const toggleFeatureValue = (feature: Feature) => {
    const currentValue = flags[feature]?.enabled;
    setFeatureValue(feature, !currentValue);
  };
  const context = { features: flags, toggleFeatureValue, setFeatureValue };

  return <FeatureFlagsContext.Provider value={context}>{children}</FeatureFlagsContext.Provider>;
}

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

/**
 * Pass the feature flag key to know if a feature is enabled or not
 */
export function useIsFeatureEnabled(flag: Feature) {
  const {
    features: { [flag]: featureValue }
  } = useFeatureFlags();
  return featureValue?.enabled === true;
}
