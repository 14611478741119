import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { CalendarIcon, XCircleIcon } from '../../Icons';
import { Input } from '../../Input';
import { Popover } from '../../Popover';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../constant';
import { DatePicker } from '../DatePicker';
import { useInternalValue, usePickerInput } from '../hooks';
import { PICKER_VARIANT } from '../types';
import { isDateValid } from '../utils/utils';
export const DefaultDateInput = function (_a) {
    var { dateFormat = DEFAULT_DATE_FORMAT, popoverProps, showActionFooter = false, pickerProps, value: propValue, onChange } = _a, props = __rest(_a, ["dateFormat", "popoverProps", "showActionFooter", "pickerProps", "value", "onChange"]);
    const getDateString = usePersistentCallback((date) => {
        if (pickerProps === null || pickerProps === void 0 ? void 0 : pickerProps.showTime) {
            return format(date, pickerProps.showTime ? `${dateFormat}, ${pickerProps.timeFormat || DEFAULT_TIME_FORMAT}` : dateFormat);
        }
        return format(date, dateFormat);
    });
    const { value: val, onChange: setVal, sync, reset } = useInternalValue({
        value: propValue,
        onChange: onChange,
        autoSync: !showActionFooter
    });
    const inputString = useMemo(() => (val ? getDateString(val) : ''), [getDateString, val]);
    const { popoverOpen, containerRef, iconRef: calendarIconRef, handleInputChange, handleSelect, handlePopupOutsideInterraction, onApply, onCancel } = usePickerInput({
        format: (pickerProps === null || pickerProps === void 0 ? void 0 : pickerProps.showTime) ? `${dateFormat}, ${(pickerProps === null || pickerProps === void 0 ? void 0 : pickerProps.timeFormat) || DEFAULT_TIME_FORMAT}` : dateFormat,
        onChange: setVal,
        isValid: (val, format) => isDateValid(val, format),
        sync,
        reset
    });
    const popover = (_jsx(Popover, Object.assign({ overlay: _jsx(DatePicker, Object.assign({}, pickerProps, { variant: PICKER_VARIANT.DATE, value: val, dateFormat: dateFormat, onChange: handleSelect, onApply: onApply, onCancel: onCancel, showActionFooter: showActionFooter })), overlayStyle: { border: 'none', boxShadow: 'none', backgroundColor: 'transparent' }, visible: popoverOpen, onInteractOutside: handlePopupOutsideInterraction }, popoverProps, { children: _jsx("div", { ref: calendarIconRef, children: popoverOpen ? _jsx(XCircleIcon, { "data-action": 'cancel', type: 'solid' }) : _jsx(CalendarIcon, { type: 'default' }) }) })));
    return (_jsx("div", { ref: containerRef, children: _jsx(Input, Object.assign({}, props, { value: inputString, onChange: handleInputChange, placeholder: dateFormat.toLocaleLowerCase(), icon: popover, iconPlacement: 'right', delay: 0 })) }));
};
