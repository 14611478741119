import { Alert, Badges, Button, Dialog, Ellipsis, Stack, StackItem, theme } from '@prophecy/ui';
import { BASE_PATH } from '@prophecy/utils/history';
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Private_Routes } from '../../common/url';
import { UserTypes } from '../../common/UserTypes';
import { useAppMetadata } from '../../context/appMetadata';
import { isConnectedToDevCluster } from '../../utils/getServerUrl';
import { useDownServiceStatus } from './util';

const StyledAlert = styled(Alert)`
  border-radius: 0;
  user-select: none;
`;

const StyledHeading = styled.div`
  width: 70px;
`;

const ServiceDownMessage: { [key: string]: string } = {
  Execution: 'Spark is temporarily unavailable. The Spark cluster may be inaccessible at this time.',
  'CI / CD': 'Unit testing is temporarily unavailable.',
  Lineage: 'Data lineage is temporarily unavailable.',
  App: ' is temporarily unavailable.'
};
export function ServiceDownNotification() {
  const { user } = useAppMetadata();
  const isAdmin = user?.type === UserTypes.ClusterAdmin;

  const data = useDownServiceStatus();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isMonitoringPage =
    matchPath(Private_Routes.Settings.tab.getUrl({ tab: 'admin' }), location.pathname) &&
    searchParams.get('tab') === 'monitoring';

  const aboutUrl = `${BASE_PATH}${Private_Routes.Settings.tab.getUrl({ tab: 'admin' }, { tab: 'monitoring' })}`;

  const isOtherServiceDown = !data?.isProphecyDown && data?.anyServiceDown;
  const [snoozeNotice, toggleSnooze] = useState(isOtherServiceDown);
  //Notification should be shown only if metadata/app are up

  useEffect(() => {
    toggleSnooze(isOtherServiceDown);
  }, [isOtherServiceDown]);

  if (!snoozeNotice || isConnectedToDevCluster() || isMonitoringPage || !isAdmin) {
    return null;
  }

  const downServices = data?.data.services.filter((service) => service.statusCode === 503) || [];
  const serviceName = downServices?.[0]?.name as string;

  const message = (
    <StackItem>
      {ServiceDownMessage[serviceName] ? ServiceDownMessage[serviceName] : serviceName + ServiceDownMessage['App']}{' '}
      Check the{' '}
      <a href={aboutUrl} target='_blank' rel='noreferrer'>
        status page
      </a>{' '}
      for updates or contact Prophecy Support for assistance.
    </StackItem>
  );

  const getMoreDetail = async () => {
    const allowed = await Dialog.warning({
      title: `Services Down`,
      children: (
        <Stack gap={theme.spaces.x12} padding={theme.spaces.x12}>
          {downServices.map((service, index) => {
            const name = service.name;
            return (
              <Stack key={index} direction='horizontal' gap={theme.spaces.x12}>
                <Badges>
                  <StyledHeading>
                    <Ellipsis tooltip={true}>{name}</Ellipsis>{' '}
                  </StyledHeading>
                </Badges>
                {message}
              </Stack>
            );
          })}
        </Stack>
      ),
      okButton: { children: 'OK', block: false }
    });
    if (!allowed) {
      return;
    }
  };

  return (
    <StyledAlert key={'maintenanceNotification'} closable={true} onClose={() => toggleSnooze(false)}>
      <Stack direction='horizontal' width='100%' gap={theme.spaces.x8} align='space-between'>
        {message}
        {downServices.length > 1 && (
          <Button onClick={getMoreDetail} size='s'>
            More
          </Button>
        )}
      </Stack>
    </StyledAlert>
  );
}
