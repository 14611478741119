import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/roboto-mono';
import '@fontsource/roboto-mono/500.css';
import '@fontsource/roboto-mono/600.css';
import '@fontsource/roboto-mono/700.css';
export const theme = {
    outlineWidth: '4px',
    newBreakpoints: {
        // TODO: replace newBreakpoints with breakpoints once all pages are covered with layout component
        m: '1024px',
        l: '1280px',
        xl: '1330px'
    },
    breakpoints: {
        xxs: '320px',
        xs: '480px',
        s: '768px',
        m: '1024px',
        l: '1300px',
        xl: '1450px'
    },
    fontWeight: {
        normal: 400,
        medium: 500,
        semiBold: 600,
        bold: 700
    },
    fontFamily: {
        text: 'Inter',
        code: 'Roboto Mono'
    },
    fontSizes: {
        x10: '10px',
        x12: '12px',
        x13: '13px',
        x14: '14px',
        x15: '15px',
        x16: '16px',
        x18: '18px',
        x20: '20px',
        x22: '22px',
        x24: '24px',
        x28: '28px',
        x30: '30px',
        x36: '36px',
        x48: '48px',
        x60: '60px',
        x72: '72px'
    },
    lineHeight: {
        auto: 'normal',
        x16: '16px',
        x18: '18px',
        x19: '19px',
        x20: '20px',
        x21: '21px',
        x22: '22px',
        x24: '24px',
        x28: '28px',
        x30: '30px',
        x32: '32px',
        x38: '38px',
        x44: '44px',
        x60: '60px',
        x72: '72px',
        x90: '90px'
    },
    sizes: {
        x4: '4px',
        x6: '6px',
        x8: '8px',
        x10: '10px',
        x12: '12px',
        x14: '14px',
        x16: '16px',
        x18: '18px',
        x20: '20px',
        x22: '22px',
        x24: '24px',
        x28: '28px',
        x30: '30px',
        x32: '32px',
        x36: '36px',
        x40: '40px',
        x44: '44px',
        x45: '45px',
        x48: '48px',
        x56: '56px',
        x64: '64px'
    },
    spaces: {
        //Not used in design mocks, redundant
        x2: '2px',
        x6: '6px',
        x10: '10px',
        x14: '14px',
        x22: '22px',
        x28: '28px',
        x36: '36px',
        x56: '56px',
        //Defined spaces in design system
        x4: '4px',
        x8: '8px',
        x12: '12px',
        x16: '16px',
        x20: '20px',
        x24: '24px',
        x32: '32px',
        x40: '40px',
        x48: '48px',
        x64: '64px',
        x80: '80px',
        x96: '96px',
        x128: '128px',
        x160: '160px',
        x192: '192px',
        x224: '224px',
        x256: '256px'
    },
    radius: {
        xxxs: '1px',
        xxs: '2px',
        xs: '4px',
        s: '6px',
        m: '8px',
        l: '12px',
        xl: '16px',
        x24: '24px',
        xxl: '32px'
    },
    colors: {
        /*new color*/
        primary25: '#F7F8FE',
        primary50: '#EFF2FE',
        primary100: '#E1E7FD',
        primary200: '#C9D2FA',
        primary300: '#A7B5F6',
        primary400: '#828EF1',
        primary500: '#626AE9',
        primary600: '#4C4DDC',
        primary700: '#403FC2',
        primary800: '#35359D',
        primary900: '#30317C',
        /** new secondary */
        secondary25: '#F7FEFF',
        secondary50: '#EFFDFF',
        secondary100: '#D7F9FD',
        secondary200: '#B5F1FA',
        secondary300: '#87E5F6',
        secondary400: '#60D0EA',
        secondary500: '#06B6D4',
        secondary600: '#0891b2',
        secondary700: '#0e7490',
        secondary800: '#155e75',
        secondary900: '#164e63',
        white: '#FFFFFF',
        /*New greys: Replace **1 with **0 */
        grey25: '#FCFCFD',
        grey50: '#F9FAFB',
        grey100: '#F2F4F7',
        grey200: '#EAECF0',
        grey300: '#D0D5DD',
        grey400: '#98A2B3',
        grey500: '#667085',
        grey600: '#475467',
        grey700: '#344054',
        grey800: '#1D2939',
        grey900: '#101828',
        /*new error color */
        error25: '#FFFBFA',
        error50: '#FEF3F2',
        error100: '#FEE4E2',
        error200: '#FECDCA',
        error300: '#FDA29B',
        error400: '#F97066',
        error500: '#F04438',
        error600: '#D92D20',
        error700: '#B42318',
        error800: '#912018',
        error900: '#7A271A',
        /*new success color*/
        success25: '#F6FEF9',
        success50: '#ECFDF3',
        success100: '#D1FADF',
        success200: '#A6F4C5',
        success300: '#6CE9A6',
        success400: '#32D583',
        success500: '#12B76A',
        success600: '#039855',
        success700: '#027A48',
        success800: '#05603A',
        success900: '#054F31',
        /*new warning color below 101 is new failure100*/
        warning25: '#FFFCF5',
        warning50: '#FFFAEB',
        warning100: '#FEF0C7',
        warning200: '#FEDF89',
        warning300: '#FEC84B',
        warning400: '#FDB022',
        warning500: '#F79009',
        warning600: '#DC6803',
        warning700: '#B54708',
        warning800: '#93370D',
        warning900: '#7A2E0E',
        /*new blue color*/
        blue25: '#F5FAFF',
        blue50: '#EFF8FF',
        blue100: '#D1E9FF',
        blue200: '#B2DDFF',
        blue300: '#84CAFF',
        blue400: '#53B1FD',
        blue500: '#2E90FA',
        blue600: '#1570EF',
        blue700: '#175CD3',
        blue800: '#1849A9',
        blue900: '#194185',
        blueish_grey25: '#FCFCFD',
        blueish_grey50: '#F8F9FC',
        blueish_grey100: '#EAECF5',
        blueish_grey200: '#D5D9EB',
        blueish_grey300: '#AFB5D9',
        blueish_grey400: '#717BBC',
        blueish_grey500: '#4E5BA6',
        blueish_grey600: '#3E4784',
        blueish_grey700: '#363F72',
        blueish_grey800: '#293056',
        blueish_grey900: '#101323',
        pink25: '#FEF6FB',
        pink50: '#FDF2FA',
        pink100: '#FCE7F6',
        pink200: '#FCCEEE',
        pink300: '#FAA7E0',
        pink400: '#F670C7',
        pink500: '#EE46BC',
        pink600: '#DD2590',
        pink700: '#C11574',
        pink800: '#9E165F',
        pink900: '#851651',
        orange25: '#FFFAF5',
        orange50: '#FFF6ED',
        orange100: '#FFEAD5',
        orange200: '#FDDCAB',
        orange300: '#FEB273',
        orange400: '#FD853A',
        orange500: '#FB6514',
        orange600: '#EC4A0A',
        orange700: '#C4320A',
        orange800: '#9C2A10',
        orange900: '#7E2410',
        rose25: '#FFF5F6',
        rose50: '#FFF1F3',
        rose100: '#FFE4E8',
        rose200: '#FECDD6',
        rose300: '#FEA3B4',
        rose400: '#FD6F8E',
        rose500: '#F63D68',
        rose600: '#E31B54',
        rose700: '#C01048',
        rose800: '#A11043',
        rose900: '#89123E',
        green_light25: '#FAFEF5',
        green_light50: '#F3FEE7',
        green_light100: '#E4FBCC',
        green_light200: '#D0F8AB',
        green_light300: '#A6EF67',
        green_light400: '#85E13A',
        green_light500: '#66C61C',
        green_light600: '#4CA30D',
        green_light700: '#3B7C0F',
        green_light800: '#326212',
        green_light900: '#2B5314',
        teal25: '#F6FEFC',
        teal50: '#F0FDF9',
        teal100: '#CCFBEF',
        teal200: '#99F6E0',
        teal300: '#5FE9D0',
        teal400: '#2ED3B7',
        teal500: '#15B79E',
        teal600: '#0E9384',
        teal700: '#107569',
        teal800: '#125D56',
        teal900: '#134E48',
        blue_light25: '#F5FBFF',
        blue_light50: '#F0F9FF',
        blue_light100: '#E0F2FE',
        blue_light200: '#B9E6FE',
        blue_light300: '#7CD4FD',
        blue_light400: '#36BFFA',
        blue_light500: '#0BA5EC',
        blue_light600: '#0086C9',
        blue_light700: '#026AA2',
        blue_light800: '#065986',
        blue_light900: '#0B4A6F',
        purple25: '#FAFAFF',
        purple50: '#F4F3FF',
        purple100: '#EBE9FE',
        purple200: '#D9D6FE',
        purple300: '#BDB4FE',
        purple400: '#9B8AFB',
        purple500: '#7A5AF8',
        purple600: '#6938EF',
        purple700: '#5925DC',
        purple800: '#4A1FB8',
        purple900: '#3E1C96',
        fuchsia25: '#FEFAFF',
        fuchsia50: '#FDF4FF',
        fuchsia100: '#FBE8FF',
        fuchsia200: '#F6D0FE',
        fuchsia300: '#EEAAFD',
        fuchsia400: '#E478FA',
        fuchsia500: '#D444F1',
        fuchsia600: '#BA24D5',
        fuchsia700: '#9F1AB1',
        fuchsia800: '#821890',
        fuchsia900: '#6F1877',
        yellow25: '#FEFDF0',
        yellow50: '#FEFBE8',
        yellow100: '#FEF7C3',
        yellow200: '#FEEE95',
        yellow300: '#FDE272',
        yellow400: '#FAC515',
        yellow500: '#EAAA08',
        yellow600: '#CA8504',
        yellow700: '#A15C07',
        yellow800: '#854A0E',
        yellow900: '#713B12'
    },
    zLayer: {
        xs: '1',
        s: '10',
        m: '100',
        l: '1000',
        xl: '10000',
        xxl: '100000',
        xxxl: '1000000000'
    },
    shadows: {
        xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
        lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
        xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
        '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.14)'
    },
    transition: {
        s: '0.2s ease-in-out',
        m: '0.4s ease-in-out',
        l: '0.6s ease-in-out'
    }
};
export var BaseColor;
(function (BaseColor) {
    BaseColor["primary"] = "primary";
    BaseColor["secondary"] = "secondary";
    BaseColor["grey"] = "grey";
    // red color
    BaseColor["error"] = "error";
    //Amber color
    BaseColor["warning"] = "warning";
    //emrald color
    BaseColor["success"] = "success";
    BaseColor["blue"] = "blue";
    BaseColor["blueish_grey"] = "blueish_grey";
    BaseColor["pink"] = "pink";
    BaseColor["orange"] = "orange";
    BaseColor["rose"] = "rose";
    BaseColor["green_light"] = "green_light";
    BaseColor["teal"] = "teal";
    BaseColor["blue_light"] = "blue_light";
    BaseColor["purple"] = "purple";
    BaseColor["fuchsia"] = "fuchsia";
    BaseColor["yellow"] = "yellow";
    BaseColor["transparent"] = "transparent";
})(BaseColor || (BaseColor = {}));
