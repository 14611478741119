import { tokens as iconTokens } from '../Icons/tokens';
import { theme } from '../theme';
import { focusedShadow } from '../theme/utils';
export const tokens = {
    Input: {
        padding: theme.spaces.x12,
        color: theme.colors.grey900,
        boxShadow: theme.shadows.xs,
        iconColor: theme.colors.grey500,
        hintIconSize: theme.fontSizes.x18,
        Title: {
            color: theme.colors.grey500
        },
        Label: {
            fontSize: theme.fontSizes.x12,
            color: theme.colors.grey700,
            iconColor: theme.colors.grey500,
            iconSize: theme.sizes.x16
        },
        HelpText: {
            fontSize: theme.fontSizes.x12,
            primary: {
                color: theme.colors.grey500
            },
            error: {
                color: theme.colors.error600
            },
            warning: {
                color: theme.colors.warning600
            }
        },
        primary: {
            backgroundColor: theme.colors.white,
            color: theme.colors.grey900,
            borderColor: theme.colors.grey300,
            hover: {
                color: theme.colors.grey900
            },
            focus: {
                borderColor: theme.colors.primary300,
                boxShadow: focusedShadow(theme.colors.primary100)
            }
        },
        error: {
            backgroundColor: theme.colors.white,
            color: theme.colors.grey900,
            borderColor: theme.colors.error300,
            hover: {
                color: theme.colors.grey900
            },
            focus: {
                borderColor: theme.colors.error300,
                boxShadow: focusedShadow(theme.colors.error100)
            }
        },
        disabled: {
            backgroundColor: theme.colors.grey50,
            borderColor: theme.colors.grey300,
            color: theme.colors.grey500
        },
        size: {
            s: {
                fontSize: theme.fontSizes.x13,
                height: theme.sizes.x28,
                padding: theme.spaces.x8,
                paddingVertical: 0,
                borderRadius: theme.radius.s,
                iconSize: iconTokens.Icon.xs.size,
                gap: theme.spaces.x4
            },
            m: {
                fontSize: theme.fontSizes.x14,
                height: theme.sizes.x40,
                padding: theme.spaces.x12,
                paddingVertical: 0,
                borderRadius: theme.radius.m,
                iconSize: iconTokens.Icon.s.size,
                gap: theme.spaces.x6
            },
            l: {
                fontSize: theme.fontSizes.x15,
                height: theme.sizes.x48,
                padding: theme.spaces.x12,
                paddingVertical: theme.spaces.x6,
                borderRadius: theme.radius.m,
                iconSize: iconTokens.Icon.m.size,
                gap: theme.spaces.x6
            }
        }
    },
    InlineInput: {
        padding: theme.spaces.x2,
        iconSize: iconTokens.Icon.xs.size,
        focus: {
            borderColor: theme.colors.grey100
        }
    },
    GhostInput: {
        fontWeight: theme.fontWeight.medium,
        borderRadius: 0,
        iconSize: iconTokens.Icon.xs.size,
        borderColor: theme.colors.grey200,
        color: theme.colors.grey500,
        iconColor: theme.colors.grey500,
        padding: theme.spaces.x4,
        focus: {
            color: theme.colors.grey900,
            borderColor: theme.colors.primary300
        },
        loading: {
            iconColor: theme.colors.grey700
        },
        size: {
            m: {
                fontSize: theme.fontSizes.x18,
                height: theme.sizes.x32,
                gap: theme.spaces.x12
            },
            l: {
                fontSize: theme.fontSizes.x24,
                height: theme.sizes.x36,
                gap: theme.spaces.x12
            }
        }
    },
    GhostDescription: {
        fontSize: theme.fontSizes.x14,
        lineHeight: theme.lineHeight.x20,
        minHeight: theme.sizes.x45
    },
    Root: {
        gap: theme.spaces.x6
    }
};
